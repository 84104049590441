export const state = () => ({
  displayLeftMenu: false,
  displaySubMenu: false, // menu
  displaySearchBar: false, // search bar
  scroll: false,
  slimHeader: false,
  event: {},
  subMenuItems: {
    dossiers: {},
  },
});

export const mutations = {
  setSubMenuVisibility(state) {
    state.subMenu.display = !state.subMenu.display;
  },
  hideLeftMenu(state) {
    state.displayLeftMenu = false;
  },
  activateScroll(state) {
    state.scroll = true;
  },
  desactivateScroll(state) {
    state.scroll = false;
  },
  openSubMenu(state) {
    state.displaySubMenu = true;
  },
  closeSubMenu(state) {
    state.displaySubMenu = false;
  },
  openSearchBar(state) {
    state.displaySearchBar = true;
  },
  closeSearchBar(state) {
    state.displaySearchBar = false;
  },
  openLeftMenu(state) {
    state.displayLeftMenu = true;
  },
  closeLeftMenu(state) {
    state.displayLeftMenu = false;
  },
  setSlimHeader(state, val) {
    state.slimHeader = val;
  },
  setEvent(state, val) {
    state.event = val;
  },
  setFolders(state, val) {
    state.subMenuItems.dossiers = val;
  },
};

export const actions = {
  handleSubMenu({ commit, state }) {
    if (!state.displaySubMenu) {
      commit("closeSearchBar");
      commit("openSubMenu");
    } else {
      commit("closeSubMenu");
    }
  },
  handleSearchBar({ commit, state }) {
    if (!state.displaySearchBar) {
      commit("closeSubMenu");
      commit("openSearchBar");
    } else {
      commit("closeSearchBar");
    }
  },
  handleLeftMenu({ commit, state }) {
    if (!state.displayLeftMenu) {
      commit("openLeftMenu");
    } else {
      commit("closeLeftMenu");
    }
  },
  closeSubMenu({ commit }) {
    commit('closeSubMenu');
  },
  async getFoldersAndEvent({ commit }) {
    try {
      let temp = await this.$headerRepository.get();
      commit("setFolders", temp.dossiers);
      commit("setEvent", temp.tag_event);
    } catch(e) {
      console.error(e)
      commit("setFolders", {});
      commit("setEvent", null);
    }
  },
};
