export const state = () => ({
    username: null
})
export const mutations = {
  SET_USERNAME(state, username) {
    state.username = username
  }
}

export const actions = {
 setUsername({commit}, username) {
   commit('SET_USERNAME', username)
 }
}