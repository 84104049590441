export const state = () => ({
  tag: null,
  filter: undefined
})

export const mutations = {
  setTag(state, tag) {
    state.tag = tag
  },
  setFilter(state, filter) {
    state.filter = filter
  },
}

export const actions = {
  
  async get_tag_contents_by_page({ commit, state }, params) {
    params.slug = encodeURI(unescape(params.slug));
    let tag = await this.$tagByNameRepository.getByIdAndPage(params.slug, params.page_id, state.filter)
    commit('setTag', tag)
  }
}