export const state = () => ({
  events: null,
  pager: null,
  dates: null,
  filters: {
    radio: null,
    page: 0,
    date: "next",
  }
});
export const mutations = {
  setEvents(state, events) {
    state.events = events;
  },
  setPager(state, pager) {
    state.pager = pager;
  },
  setDates(state, dates) {
    state.dates = dates;
  },
  setFilters(state, filters) {
    state.filters = { ...state.filters, ...filters };
  },
};

export const actions = {
  async getEvents({ commit, state }, attr) {
    const { radio, date, ...restFilters } = state.filters;
    let params = {
      ...restFilters,
    };

    if(attr?.firstPage) {
      params.page = null
    }
    
    if (radio) params.radio = radio;
    if (date) {
      const formattedDate = date.replace('-', '_');
      params.date = formattedDate;
    }

    const url = "events";
    try {
      const events = await this.$eventsRepository.getByUrl(url, params);
      if (events) {
        commit("setEvents", events.content);
        commit("setPager", events.pager);
      }
    } catch(e) {
      throw new Error(e.message)
    }
  },
  async addEvents({ commit, state }) {
    const { radio, date, ...restFilters } = state.filters;
    const params = {
      ...restFilters,
    };
    if(params.page>0) {
      params.page = params.page-1
    }
    if (radio) params.radio = radio;
    if (date) {
      const formattedDate = date.replace('-', '_');
      params.date = formattedDate;
    }

    const url = "events";
    try {
      const events = await this.$eventsRepository.getByUrl(url, params);
      let eventsBeforeFetch = state.events;
      for (let key in events.content) {
        // If this key is already in the original data, append the events
        if (eventsBeforeFetch.hasOwnProperty(key)) {
          eventsBeforeFetch[key] = [...eventsBeforeFetch[key], ...events.content[key]];
        }
        // If this key is not in the original data, add it
        else {
          eventsBeforeFetch[key] = events.content[key];
        }
      }
  
      if (events) {
        commit("setEvents", eventsBeforeFetch);
        commit("setPager", events.pager);
      }

    } catch(e) {
      throw new Error(e.message)
    }
  },
  handleFilters({ commit }, filters) {
    commit("setFilters", filters);
  },
  handleDates({ commit }, dates) {
    commit("setDates", dates);
  }
};
