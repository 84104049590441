export const state = () => ({
  blocks: [],
  error: '',
  info: ''
})

export const mutations = {
  setBlocks(state, blocks) {
    state.blocks = blocks
  },
  setInfo(state, info) {
    state.info = info
    state.error = ''
  },
  setError(state, error) {
    state.error = error
    state.info = ''
  }
}

export const actions = {
  async getBlocks({ commit, rootState }, id) {
    let radio = rootState.radio.radioTid ?? 9
    try {
      let blocks = await this.$regionRepository.getByIdWithQueryParam({id: id+'/'+radio})
      commit('setBlocks', blocks)
    } catch(e) {
      throw new Error('API call to region {' + id + '} failed');
    }
  }
}
