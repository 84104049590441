export const state = () => ({
  preRolls: null,
  postRolls: null,
  preRollsGlobal: null,
  postRollsGlobal: null,
  preRollShow: null,
  postRollShow: null,
  endPlay: false,
  postRollModal: false,
  preRollModal: false,
  hasPreRolls: false,
  hasPostRolls: false
})

export const mutations = {
  setPreRolls(state, preRolls) {
    state.preRolls = preRolls
  },
  setPostRolls(state, postRolls) {

    state.postRolls = postRolls
  },
  setPreRollsGlobal(state, preRollsGlobal) {
    state.preRollsGlobal = preRollsGlobal
  },
  setPostRollsGlobal(state, postRollsGlobal) {
    state.postRollsGlobal = postRollsGlobal
  },
  setPreRollShow(state, preRollShow) {
    state.preRollShow = preRollShow
  },
  setPostRollShow(state, postRollShow) {
    state.postRollShow = postRollShow
  },
  setPostRollModal(state) {
    state.postRollModal = !state.postRollModal
  },
  setPreRollModal(state) {
    state.preRollModal = !state.preRollModal
  },
  setHasPreRolls(state) {
    state.hasPreRolls = true
  },
  setHasPostRolls(state) {
    state.hasPostRolls = !state.hasPostRolls
  }
 }

export const actions = {
  async getPreRolls({ commit }, params) {
    let PreRolls = await this.$defaultRepository.getByIdWithParam(params)
    commit('setPreRolls', PreRolls)
  },
  async getPostRolls({ commit }, params) {
    let PostRolls = await this.$defaultRepository.getByIdWithParam(params)
    commit('setPostRolls', PostRolls)
  },

  async getPreRollsGlobal({ commit }) {
    let params = {
      'id' : 'rolls',
      'args' : {
        'roll_type':'Pre-roll',
        'roll_use': 'Global'
      }
    }
    let preRollsGlobal = await this.$defaultRepository.getByIdWithParam(params)

    commit('setPreRollsGlobal', preRollsGlobal)

    if(preRollsGlobal !== null && Object.keys(preRollsGlobal).length > 0) {

      if(!state.hasPreRolls) {
        commit('setHasPreRolls')
      }
    }
  },

  async getPostRollsGlobal({ commit }) {
    let params = {
      'id' : 'rolls',
      'args' : {
        'roll_type':'Post-roll',
        'roll_use': 'Global'
      }
    }
    let postRollsGlobal = await this.$defaultRepository.getByIdWithParam(params)

    commit('setPostRollsGlobal', postRollsGlobal)

    if(postRollsGlobal !== null && Object.keys(postRollsGlobal).length > 0) {
      commit('setPostRolls', postRollsGlobal)
    }
  },

  async getPreRollShow({ commit }, tid) {
    let preRollShow = await this.$nodeRepository.getByUrl('media/'+tid+'/edit')

    commit('setPreRollShow', preRollShow)
  },

  async getPostRollShow({ commit }, tid) {
    let postRollShow = await this.$nodeRepository.getByUrl('media/'+tid+'/edit')

    commit('setPostRollShow', postRollShow)
  },
}
