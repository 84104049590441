export default () => resource => ({

  alignImg(){
    if (document.readyState !== "loading") {
      setTimeout(function(){
        doJob()
      }, 0);
    } else {
      document.addEventListener("DOMContentLoaded", function(){
        doJob()
      }, false);
    }
  },

})

function doJob(){
  let currentElementCenter = document.querySelectorAll('[data-align="center"]');
  let currentElementCenterByClass = document.querySelectorAll('.align-center');

  if(currentElementCenter){
    currentElementCenter.forEach(function() {

      currentElementCenter[0].parentElement.parentElement.classList.add('img-center')
    })
  }

  if(typeof (currentElementCenterByClass) !== 'undefined'){
    currentElementCenterByClass.forEach(function(item) {
      item.parentElement.parentElement.classList.add(...['is-flex', 'is-justify-content-center',  'is-align-items-center'])
    })
  }

  let currentElementLeft = document.querySelectorAll('[data-align="left"]');
  let currentElementLeftByClass = document.querySelectorAll('.align-left');

  if(currentElementLeft){
    currentElementLeft.forEach(function() {
      currentElementLeft[0].parentElement.parentElement.classList.add('img-left')
    })
  }

  if(typeof (currentElementLeftByClass) !== 'undefined'){
    currentElementLeftByClass.forEach(function(item) {
      item.parentElement.classList.add(...['is-flex', 'is-justify-content-flex-start',  'is-align-items-center'])
    })
  }

  let currentElementRight = document.querySelectorAll('[data-align="right"]');
  let currentElementRightByClass = document.querySelectorAll('.align-right');

  if(currentElementRight){
    currentElementRight.forEach(function() {
      currentElementRight[0].parentElement.parentElement.classList.add('img-right')
    })
  }
  if(typeof (currentElementRightByClass) !== 'undefined'){
    currentElementRightByClass.forEach(function(item) {
      item.parentElement.classList.add(...['is-flex', 'is-justify-content-flex-end',  'is-align-items-center'])
    })
  }

}
