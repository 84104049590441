export const state = () => ({
  top: {},
});

export const mutations = {
  setTopPodcastBlocks(state, blocks) {
    state.top = blocks;
  },
};

export const actions = {
  async getTopPodcastsBlocks({ commit }) {
    try {
      let blocks = await this.$regionRepository.getByIdWithQueryParam({
        id: "top_podcasts",
      });
      commit("setTopPodcastBlocks", blocks);
    } catch(e) {
      throw new Error('API call to region {top_podcast} failed');
    }
  },
};
