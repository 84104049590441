export const state = () => ({
  onload: false
});

export const mutations = {
  setOnLoad(state, value) {
    state.onload = value
  }
};

export const actions = {
    loading({commit}) {
        commit('setOnLoad', true)
    },
    loaded({commit}) {
        commit('setOnLoad', false)
    },
}