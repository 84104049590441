import { Auth } from 'aws-amplify'
export const state = () => ({
  isAuthenticated: false,
  user: null
})

export const mutations = {
  set(state, user) {
    state.isAuthenticated = !!user
    state.user = user
  }
}

export const actions = {
  async load({ commit }) {
    try {
      const user = await Auth.currentAuthenticatedUser()
      commit('set', user)
      return user
    } catch (error) {
      commit('set', null)
    }
  },

  async register(_, {username, password, lastname, firstname, favoriteRadio}){
    const data = {
      username: username,
      password: password,
      attributes: {
        email: username,
        family_name: lastname,
        given_name: firstname,
        'custom:favorite_radio': favoriteRadio
      }
    }
    const user =  await Auth.signUp(data)
    return user    
  },

  async confirmRegistration({}, { username, code }) {
    return await Auth.confirmSignUp(username, code)
  },

  async resendConfirmationCode({}, username) {
    return await Auth.resendSignUp(username);
  },

  async sendPasswordResetCode({}, email) {
    return await Auth.forgotPassword(email);
  },

  async resetUserPassword({}, {username, code, newPassword}) {
    return await Auth.forgotPasswordSubmit(username, code, newPassword);
  },

  async updateUserPassword({}, {oldPassword, newPassword}) {
    return await Auth.changePassword(this.$auth.user, oldPassword, newPassword)
  },

  async updateUser({dispatch}, {lastname, firstname, favorite_radio}) {
    return await Auth.updateUserAttributes(this.$auth.user, {
      family_name: lastname,
      given_name: firstname,
      'custom:favorite_radio': favorite_radio
    })
      .then(() => {
        dispatch('refreshUser')
      })
  },

  async updateUsername({}, username) {
    return await Auth.updateUserAttributes(this.$auth.user, {
      email: username
    })
  },

  async confirmUpdateUsername({dispatch}, code) {
    return await Auth.verifyUserAttributeSubmit(this.$auth.user, 'email', code)
      .then(() => {
        dispatch('refreshUser')
      })
  },

  async verifyPassword({}, password) {
    return Auth.signIn(this.$auth.email, password)
    // return Auth.changePassword(this.$auth.user, password, password)
  },

  async deleteUser({}) {
    return await this.$auth.user.deleteUser((err, result) => {
      if (err) {
        throw err
      }
    })
  },

  async refreshUser({ commit }) {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true })
    commit('set', user)
    return user
  },

  async login({ commit, dispatch, rootState }, { username, password }) {
    return await Auth.signIn(username, password)
      .then((user) => {
        commit('set', user)
        dispatch('favorites/getUserFavorites', {}, {root:true})

        // Changement de radio si la radio en cours est le natio ou aucune
        if (this.$auth.favorite_radio != '' && [this.$config.nationalCode, null].includes(rootState.radio.radioCode) ) {
          dispatch('radio/getRadioByCode', {radioCode: this.$auth.favorite_radio}, {root:true})
        }
      })
      .catch((error) => {
        console.log(error)
        throw error
      })
  },

  async logout({ commit }) {
    await Auth.signOut()
    commit('set', null)
    commit('navigation/setInfo', 'Vous avez été déconnecté avec succès.', {root: true})
    this.$router.push({path: "/", query: {disconnected: true}})
  }
}