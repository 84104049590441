import dayjs from "dayjs";

/* ==========================================================
 *
 * Gère uniquement les communications avec tout ce qui est
 * externe à l'application (API, cookies, etc.)
 *
 * Respecter le principe de l'unilatéralité du flux de donnée:
 * service => store => component
 *
 * De plus, le service ne doit pas avoir de dépendance avec le
 * store ou le component. Il doit être indépendant.
 * L'utilisation de fonctions utilitaires est possible.
 *
 * Passer un argument dans une méthode du service est possible.
 * En revanche, faire appel au store directement dans une méthode
 * du service est interdit. Cela créerait une dépendance avec
 * le store.
 *
 * ========================================================== */

export default (store, $axios, cookies, $config, app, ctx) => (resource) => ({
  data() {
    const soundData = {
      audio_file: "",
      digas: "",
      id: `livestream`,
      partners: [],
      show: {
        alias: "/",
        id: `livestream`,
        title: "Le direct",
      },
      title: "Le direct",
      url_linked_article: "",
      visual: {
        alt: "",
        url: "",
      },
    };

    return {
      defaultSoundData: soundData,
    };
  },

  /**
   * 
   * @param {*} url 
   * @param {*} platform 
   * @returns {sound, source}
   */
  createSoundInstance(url, platform) {
    let sound = document.createElement('audio')
    let source = document.createElement('source')
    if (store.state.audioPlayer.isEmbed) {
      url = url.replace('{source_code}', $config.amSourceEmbed) // Set audiomeans embed source
    }
    else {
      url = url.replace('{source_code}', $config.amSourceSite) // Set audiomeans site source
    }
    source.src = url
    sound.volume = store.state.audioPlayer.volumeSettings.volume
    sound.muted = store.state.audioPlayer.volumeSettings.mute
    sound.preload = store.state.audioPlayer.isEmbed ? "none" : "auto" // pas de preload si embed
    sound.appendChild(source)
    return {sound, source}
  },
  async getLiveSoundData(radioId) {
    const radioCookie = cookies.get("cookie");
    const radioCode = radioId || radioCookie?.radioCode || "RCFNational";

    const response = await $axios.get(`/api/dab/${radioCode}?_format=json`);
    const data = response.data;
    const soundData = { ...this.defaultSoundData };

    soundData.audio_file = radioCookie.radioLiveURL;
    soundData.id = `livestream-${radioCode}`;

    if (data) {
      const show = {
        alias: data.title_url || (soundData.show ? soundData.show.alias : ''),
        title: data.title || (soundData.show && soundData.show.title ? soundData.show.title : ''),
        episode: data.artiste
      };
      const visual = {
        alt: "",
        url: data.visual || (soundData.visual && soundData.visual.url ? soundData.visual.url : ''),
      };

      dayjs.locale("fr");

      const duration = dayjs(data.enddate).diff(
        dayjs(data.date),
        "milliseconds"
      );
      const timePassedSinceStart = dayjs().diff(dayjs(data.date), "seconds");
      const timeLeft = (duration - timePassedSinceStart * 1000);

      soundData.show = show;
      soundData.title = data.title;
      soundData.visual = visual;
      soundData.duration = duration;
      soundData.timePassedSinceStart = timePassedSinceStart;
      soundData.timeLeft = timeLeft;
    }

    return soundData;
  },
  async getPrevNextSoundData({ type, playListId, currentSoundId }) {
    currentSoundId = parseInt(currentSoundId);
    const url = `/rcf_rest_playerlist/${type}/${playListId}/${currentSoundId}?_format=json`;
    const response = await $axios.get(url);
    const data = response.data;

    return data;
  },
  // Tracking d'écoutes
  listeningTracking(sound, seek, mainSoundInstance) {
    if (!sound.isLiveStream) {
      if (Math.trunc(seek) === 30) {
        let gtmPodcastActiveObject = ctx.$gtmService.getPodcastActiveObject();
        ctx.$gtmService.push(gtmPodcastActiveObject);
      }

      // Check percent effective playing time
      let percentage = Math.trunc(seek / mainSoundInstance.duration * 100)

      if (percentage !== sound.listeningPercentage) {
        sound.listeningPercentage = percentage
        switch (percentage) {
          case 10:
          case 25:
          case 50:
          case 75:
          case 99:
            const gtmPodcastOngoingObject = ctx.$gtmService.getPodcastOngoingObject(percentage + "%");
            ctx.$gtmService.push(gtmPodcastOngoingObject);
            break;
          default:
            break;
        }
      }
    }
  }
});
