import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _f4bac86a = () => interopDefault(import('../pages/archives/index.vue' /* webpackChunkName: "pages/archives/index" */))
const _1f248c28 = () => interopDefault(import('../pages/devenez-partenaire.vue' /* webpackChunkName: "pages/devenez-partenaire" */))
const _64380e22 = () => interopDefault(import('../pages/dossiers/index.vue' /* webpackChunkName: "pages/dossiers/index" */))
const _e901e274 = () => interopDefault(import('../pages/emissions-a-z.vue' /* webpackChunkName: "pages/emissions-a-z" */))
const _303a437e = () => interopDefault(import('../pages/evenements.vue' /* webpackChunkName: "pages/evenements" */))
const _5d53c31e = () => interopDefault(import('../pages/grille-des-programmes.vue' /* webpackChunkName: "pages/grille-des-programmes" */))
const _04634098 = () => interopDefault(import('../pages/legs-donation-assurance-vie.vue' /* webpackChunkName: "pages/legs-donation-assurance-vie" */))
const _391d908a = () => interopDefault(import('../pages/mon-compte/index.vue' /* webpackChunkName: "pages/mon-compte/index" */))
const _995a0acc = () => interopDefault(import('../pages/nos-partenaires.vue' /* webpackChunkName: "pages/nos-partenaires" */))
const _5f13291d = () => interopDefault(import('../pages/points-de-vue.vue' /* webpackChunkName: "pages/points-de-vue" */))
const _665625bb = () => interopDefault(import('../pages/prieres.vue' /* webpackChunkName: "pages/prieres" */))
const _ba894d92 = () => interopDefault(import('../pages/recherche/index.vue' /* webpackChunkName: "pages/recherche/index" */))
const _6eb1dbc2 = () => interopDefault(import('../pages/test-css.vue' /* webpackChunkName: "pages/test-css" */))
const _4f17603e = () => interopDefault(import('../pages/top-podcasts.vue' /* webpackChunkName: "pages/top-podcasts" */))
const _7385fc5c = () => interopDefault(import('../pages/mon-compte/creer-mon-compte.vue' /* webpackChunkName: "pages/mon-compte/creer-mon-compte" */))
const _e705d1f0 = () => interopDefault(import('../pages/mon-compte/forgotPassword/index.vue' /* webpackChunkName: "pages/mon-compte/forgotPassword/index" */))
const _58c9e3c2 = () => interopDefault(import('../pages/mon-compte/mes-favoris.vue' /* webpackChunkName: "pages/mon-compte/mes-favoris" */))
const _7c61a4f8 = () => interopDefault(import('../pages/mon-compte/mon-profil.vue' /* webpackChunkName: "pages/mon-compte/mon-profil" */))
const _2077170a = () => interopDefault(import('../pages/vie-spirituelle/le-saint-du-jour.vue' /* webpackChunkName: "pages/vie-spirituelle/le-saint-du-jour" */))
const _063ddd80 = () => interopDefault(import('../pages/mon-compte/forgotPassword/resetPassword.vue' /* webpackChunkName: "pages/mon-compte/forgotPassword/resetPassword" */))
const _01debcea = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _34b5a1d8 = () => interopDefault(import('../pages/archives/_year/index.vue' /* webpackChunkName: "pages/archives/_year/index" */))
const _1ceb0224 = () => interopDefault(import('../pages/direct/_id/index.vue' /* webpackChunkName: "pages/direct/_id/index" */))
const _34d11b5d = () => interopDefault(import('../pages/dossiers/_folder.vue' /* webpackChunkName: "pages/dossiers/_folder" */))
const _0683831a = () => interopDefault(import('../pages/partenaires/_partner.vue' /* webpackChunkName: "pages/partenaires/_partner" */))
const _c797b182 = () => interopDefault(import('../pages/preview/_slug/index.vue' /* webpackChunkName: "pages/preview/_slug/index" */))
const _8dd527f2 = () => interopDefault(import('../pages/tag/_tag.vue' /* webpackChunkName: "pages/tag/_tag" */))
const _23d1282e = () => interopDefault(import('../pages/archives/_year/_month/index.vue' /* webpackChunkName: "pages/archives/_year/_month/index" */))
const _639116fd = () => interopDefault(import('../pages/articles/_slug/_id/index.vue' /* webpackChunkName: "pages/articles/_slug/_id/index" */))
const _1706e0b1 = () => interopDefault(import('../pages/archives/_year/_month/_day.vue' /* webpackChunkName: "pages/archives/_year/_month/_day" */))
const _6dd52725 = () => interopDefault(import('../pages/_/embed/index.vue' /* webpackChunkName: "pages/_/embed/index" */))
const _28ca1296 = () => interopDefault(import('../pages/_/_slug/index.vue' /* webpackChunkName: "pages/_/_slug/index" */))
const _5687779b = () => interopDefault(import('../pages/_/index.vue' /* webpackChunkName: "pages/_/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/archives",
    component: _f4bac86a,
    name: "archives"
  }, {
    path: "/devenez-partenaire",
    component: _1f248c28,
    name: "devenez-partenaire"
  }, {
    path: "/dossiers",
    component: _64380e22,
    name: "dossiers"
  }, {
    path: "/emissions-a-z",
    component: _e901e274,
    name: "emissions-a-z"
  }, {
    path: "/evenements",
    component: _303a437e,
    name: "evenements"
  }, {
    path: "/grille-des-programmes",
    component: _5d53c31e,
    name: "grille-des-programmes"
  }, {
    path: "/legs-donation-assurance-vie",
    component: _04634098,
    name: "legs-donation-assurance-vie"
  }, {
    path: "/mon-compte",
    component: _391d908a,
    name: "mon-compte"
  }, {
    path: "/nos-partenaires",
    component: _995a0acc,
    name: "nos-partenaires"
  }, {
    path: "/points-de-vue",
    component: _5f13291d,
    name: "points-de-vue"
  }, {
    path: "/prieres",
    component: _665625bb,
    name: "prieres"
  }, {
    path: "/recherche",
    component: _ba894d92,
    name: "recherche"
  }, {
    path: "/test-css",
    component: _6eb1dbc2,
    name: "test-css"
  }, {
    path: "/top-podcasts",
    component: _4f17603e,
    name: "top-podcasts"
  }, {
    path: "/mon-compte/creer-mon-compte",
    component: _7385fc5c,
    name: "mon-compte-creer-mon-compte"
  }, {
    path: "/mon-compte/forgotPassword",
    component: _e705d1f0,
    name: "mon-compte-forgotPassword"
  }, {
    path: "/mon-compte/mes-favoris",
    component: _58c9e3c2,
    name: "mon-compte-mes-favoris"
  }, {
    path: "/mon-compte/mon-profil",
    component: _7c61a4f8,
    name: "mon-compte-mon-profil"
  }, {
    path: "/vie-spirituelle/le-saint-du-jour",
    component: _2077170a,
    name: "vie-spirituelle-le-saint-du-jour"
  }, {
    path: "/mon-compte/forgotPassword/resetPassword",
    component: _063ddd80,
    name: "mon-compte-forgotPassword-resetPassword"
  }, {
    path: "/",
    component: _01debcea,
    name: "index"
  }, {
    path: "/archives/:year",
    component: _34b5a1d8,
    name: "archives-year"
  }, {
    path: "/direct/:id",
    component: _1ceb0224,
    name: "direct-id"
  }, {
    path: "/dossiers/:folder",
    component: _34d11b5d,
    name: "dossiers-folder"
  }, {
    path: "/partenaires/:partner?",
    component: _0683831a,
    name: "partenaires-partner"
  }, {
    path: "/preview/:slug",
    component: _c797b182,
    name: "preview-slug"
  }, {
    path: "/tag/:tag?",
    component: _8dd527f2,
    name: "tag-tag"
  }, {
    path: "/archives/:year/:month",
    component: _23d1282e,
    name: "archives-year-month"
  }, {
    path: "/articles/:slug?/:id",
    component: _639116fd,
    name: "articles-slug-id"
  }, {
    path: "/archives/:year/:month/:day",
    component: _1706e0b1,
    name: "archives-year-month-day"
  }, {
    path: "/*/embed",
    component: _6dd52725,
    name: "all-embed"
  }, {
    path: "/*/:slug",
    component: _28ca1296,
    name: "all-slug"
  }, {
    path: "/*",
    component: _5687779b,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
