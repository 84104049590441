
import { mapState, mapMutations } from 'vuex';

export default {
    components: {
      Header : () => import("@/components/navigation/Header/Header"),
      PreFooter : () => import("@/components/navigation/PreFooter"),
      Footer : () => import("@/components/navigation/Footer/Footer"),
      AudioPlayer: () => import("@/components/audio-player/AudioPlayer"),
      Modal : () => import("@/components/modal/Modal.vue"),
      RollModal : () => import( "@/components/modal/RollModal.vue"),
      DonationPopinModal : () => import( "@/components/modal/DonationPopinModal.vue"),
    },
    data() {
      return {
        prehome: [],
        prehomeRadioDon: [],
        showDonationModal: false,
      }
    },
    middleware: ['radio'],
    watch: {
      async "$route.query.disconnected"(newVal, oldVal) {
        if (oldVal == 'true' && newVal == undefined) {
          if (this.error != '') this.$store.commit('navigation/setError', '')
          if (this.info != '') this.$store.commit('navigation/setInfo', '')
        }
      }
    },
    computed: {
      ...mapState({
        audioPlayerIsOpen: (state) => {
          return (state.audioPlayer.soundData.id !== undefined)
        },
        info: (state) => {
          return state.navigation.info
        },
        error: (state) => {
          return state.navigation.error
        }
      }),
    },
    mounted() {
      this.getPreRollsGlobal()
      this.getPostRollsGlobal()
      // Initialize the listener counter service
      this.$cookieService.initListenerCounter();
      let listenerCounter = this.$cookies.get('cookieListenerCounter');

      if (this.$auth.isAuthenticated) {
        this.getUserFavorites()
        const isEligibleListenerCount = listenerCounter?.ListenerNbConnected === 2 || listenerCounter?.ListenerNbConnected === 5;

        if (isEligibleListenerCount) {
            this.$cookieService.setDonationPathway();
        }
      } else {
        this.$cookieService.setDonationPathway();
      }
      if (process.client) {
        // Remove param radio if exists
        if (this.$router.currentRoute.query.radio){
          this.$router.replace({'query': null})
        }
        // set national radio by default
        if(this.$store.state.radio.radioNationalTid == null) {
          this.$store.dispatch('radio/getRadioNational', { radioCode: this.$config.nationalCode })
        }
        
        if (listenerCounter && (listenerCounter.ListenerNb === 0 || listenerCounter.ListenerNb < 5)) {
          setTimeout(() => {
            this.checkAndShowModal()
            localStorage.setItem('donationPopinShown', true);
            // TODO : modifier le setTimeout à 30000 mls avent d'envoyer sur dev
          }, 5000);
        }
      }
    },
    methods: {
      async getUserFavorites() {
        await this.$store.dispatch('favorites/getUserFavorites')
      },
      getPreRollsGlobal() {
        this.$store.dispatch('roll/getPreRollsGlobal')
      },
      getPostRollsGlobal() {
        this.$store.dispatch('roll/getPostRollsGlobal')
      },
      showDonationPopin() {
        this.showDonationModal = true; // met à jour l'état local
      },
      hideDonationPopin() {
        this.showDonationModal = false; // met à jour l'état local
      },
      checkAndShowModal() {
        if (!localStorage.getItem('donationPopinShown')) {
          this.showDonationPopin();
          localStorage.setItem('donationPopinShown', 'true');
        }
      },
    }
  }
