export const state = () => ({
  articles: [],
  taxonomy: [],
  filter: null,
  first: null,

  // The article in article page
  article : null
})

export const mutations = {
  ARTICLES(state, articles) {
    state.articles = articles.articles
    state.taxonomy = articles.taxonomy
    state.filter = articles.filter
    state.first = articles.first
  },
  setArticle(state, article){
    state.article = article
  }
}

export const actions = {

  setArticle({ commit, state }, article) {
    commit('setArticle', article)
  },

  async get_articles_by_page({ commit }, params) {
    let articles, first;
    if(params.slug===false){
      articles = await this.$articlesRepository.getByPageNumber(params.page_id,params.filter)
      if(params.page_id === 0) {
        first = await this.$articlesTopRepository.getByPageNumber(params.page_id,params.filter)
      }
      else {
        first = null;
      }
    }
    else {
      params.slug = encodeURI(unescape(params.slug));
      articles = await this.$articlesRepository.getByIdAndPage(params.slug,params.page_id,params.filter)
      if(params.page_id === 0) {
        first = await this.$articlesTopRepository.getByIdAndPage(params.slug,params.page_id,params.filter)
      }
      else {
        first = null;
      }
    }
    let taxonomy = await this.$taxonomyRepository.getById("category")
    commit('ARTICLES', {'articles':articles, "taxonomy":taxonomy, 'filter':params.filter, 'first': first})
  }
}
