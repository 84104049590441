
/* 
  Progress permet de gérer la barre de progression
  La durée venant du back est exprimé en milisecondes.
  La position actuelle obtenu via l'élément audio est exprimé en secondes.

  Il faut donc faire attention à bien convertir les valeurs pour que la barre de progression fonctionne correctement.
  Vice versa lorsqu'on souhaite modifier la position actuel de la piste.

  TLDR:
    this.progress.value: millisecondes
    this.audioPlayer.mainSoundInstance.currentTime : secondes

  Piste d'amélioration:
    - Si la durée venant du back n'est pas utile, alors changer la valeur de la durée 
      totale (clé duration) pour l'extraire directement de l'audio. Ainsi on évite de faire des conversions.
        => this.audioPlayer.mainSoundInstance.duration
*/

import { mapActions, mapState } from "vuex";

export default {
  name: "AudioPlayerControls",
  data() {
    return {
      interval: null,
      progress: {
        value: 0,
        formattedString: "00:00",
      },
      listeningPercentage: 0,
    };
  },
  computed: {
    ...mapState({
      audioPlayer: (state) => {
        return state.audioPlayer;
      },
      currentTime : (state) => {
        return state.audioPlayer.currentTime
      },
      isLoading: (state) => {
        return state.audioPlayer.isLoading;
      },
      isPlaying: (state) => {
        return state.audioPlayer.isPlaying || state.audioPlayer.isRollPlaying;
      },
      isLiveStream: (state) => {
        return state.audioPlayer.isLiveStream
      },
      timePassedSinceStart: (state) => {
        return state.audioPlayer.soundData.timePassedSinceStart
      },
      readyState: (state) => {
        return state.audioPlayer.mainSoundInstance?.readyState
      }
    }),
    formatCurrentTime() {
      let formattedTime;
      if (this.isLiveStream) {
        formattedTime = this.formatSeconds(this.timePassedSinceStart+this.currentTime)
      } else {
        if (isNaN(this.currentTime)) return "--:--";
        formattedTime = this.formatSeconds(this.currentTime)
      }
      return formattedTime;
    },
    shouldDisablePlayButton() {
      const { isEmbed, isLiveStream, isRollPlaying, modalVisible } = this.audioPlayer;
      const isEmbedOrLiveStream = isEmbed || isLiveStream;
      const listenerCounter = isEmbedOrLiveStream ? 0 : this.$cookies.get('cookieListenerCounter').ListenerNb;
      const isListenerLimitReached = !isEmbedOrLiveStream && !this.$auth.isAuthenticated && listenerCounter <= this.$config.listenerMax;

      return isRollPlaying || (isListenerLimitReached && modalVisible);
    },
  },

  methods: {
    ...mapActions("audioPlayer", ["playLiveStream"]),

    previous() {
      this.$store.dispatch("audioPlayer/skip", "prev");
    },
    jump(direction) {
      const currentPosition = this.audioPlayer.mainSoundInstance.currentTime ;
      const jumpValue = direction === "forward" ? 30 : -10;
      const newPosition = currentPosition + jumpValue;
      this.audioPlayer.mainSoundInstance.currentTime = newPosition;
    },
    play() {
      if (this.audioPlayer.isLiveStream) {
        this.playLiveStream();
      }
      else {
        this.$store.dispatch("audioPlayer/play", { same: true });
      }
    },
    pause() {
      this.$store.dispatch("audioPlayer/pause");
    },
    stop() {
      this.$store.dispatch("audioPlayer/unloadMainSoundInstance")
      this.$store.dispatch("audioPlayer/stopLiveUpdateTimeout")
    },
    next() {
      this.$store.dispatch("audioPlayer/skip", "next");
    },
    seek(event) {
      const newPosition = Number(event.target.value) / 1000;
      this.audioPlayer.mainSoundInstance.currentTime = newPosition;
    },
    formatSeconds(totalSeconds) {
      if (isNaN(totalSeconds)) return "--:--";
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = Math.floor(totalSeconds % 60);

      const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;

      return formattedTime;
    },
  },
};
