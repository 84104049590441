export const state = () => ({
  partners: [],
  partner: [],
  filter: undefined
})

export const mutations = {
  setPartners(state, partners) {
    state.partners = partners
  },
  setPartner(state, partner) {
    state.partner = partner
  },
  setFilter(state, filter) {
    state.filter = filter
  },
  addEpisodes(state, episodes){
    state.partner.partnerEpisodes.content = [
      ...state.partner.partnerEpisodes.content,
      ...episodes
    ]
  },
  setPager(state, pager) {
    state.partner.partnerEpisodes.pager = pager
  }
}

export const actions = {
  async getPartners({ commit }, routeParams) {
    let radioId = routeParams.radioId
    let params = {
      'id': 'partners',
    }

    if(typeof (routeParams.page_id) !== "undefined") {
      params = {
        ...params,
        'args': {
          'page': routeParams.page_id
        }
      }

      if (typeof (routeParams.filter) !== "undefined" && routeParams.filter !== null) {
        let key = 'radio[0]'
        let value = routeParams.filter

        if(typeof (routeParams.filter) === "object") {
          key = 'region'
          value = routeParams.filter.regional

          commit('setFilter', {'regional': routeParams.filter})
        }
        else {
          commit('setFilter', routeParams.filter)
        }

        params['args'] = {
          ...params['args'],
          [key]: value
        }
        if (typeof (routeParams.second_filter) !== "undefined" && routeParams.filter !== null) {
          key = 'radio[1]'
          value = routeParams.second_filter
          params['args'] = {
            ...params['args'],
            [key]: value
          }
        }
      }
      else {
        commit('setFilter', undefined)
      }
    }
    if (radioId !== undefined) {
      params['args'] = {
        ...params['args'],
        'radio[0]': radioId,
        'radio[1]': "9"
      }
    }

    try {
      let partners = await this.$defaultRepository.getByIdWithParam(params)

      let blockHeader = await this.$defaultRepository.getByUrl('rest_block_content/block_image')

      partners = {
        ...partners,
        blockHeader
      }
      commit('setPartners', partners)
    } catch(e) {
      throw new Error(e.message)
    }
    

  },

  async getPartner({commit}, routeParams) {
    let argsArticles = {}
    let argsEpisodes = {}
    let url = routeParams.alias

    if (routeParams.page_id && routeParams.alias) {
      url = routeParams.slug
    }
    try {
      let partner = await this.$nodeRepository.getByUrl(url)
      commit('setPartner', partner)

    if(typeof (partner.id) !== "undefined") {

      if (routeParams.page) {
        argsArticles = {
          ...argsArticles,
          'page': routeParams.page
        }

        argsEpisodes = {
          args: {
            'page': routeParams.page
          }
        };
      }
    }

    let paramsArticles = {
      ...argsArticles,
      'id': partner.id,
    }

    let partnerArticles = await this.$articlesPerPartnerRepository.getByIdWithParam(paramsArticles)

    let paramsEpisodes = {
      ...argsEpisodes,
      'id': partner.id,
    }

    let partnerEpisodes = await this.$episodePerPartnerRepository.getByIdWithParam(paramsEpisodes)

    partner = {
      ...partner,
      partnerArticles,
      partnerEpisodes
    }

    commit('setPartner', partner)
    } catch(e) {
      throw new Error(e.message)
    }
    
  },

  async getMoreEpisodes({commit}, routeParams){
    let paramsEpisodes = {
      args: {
        'page': routeParams.page
      },
      'id' : routeParams.id
    };
    try {
      let episodes = await this.$episodePerPartnerRepository.getByIdWithParam(paramsEpisodes)
      commit('addEpisodes', episodes.content)
      commit('setPager', episodes.pager)
    } catch(e) {
      throw new Error(e.message)
    }

  }
   
}
