import { render, staticRenderFns } from "./default.vue?vue&type=template&id=3236817d"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=3236817d&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AudioPlayer: require('/web/components/audio-player/AudioPlayer.vue').default,Modal: require('/web/components/modal/Modal.vue').default})
