export const state = () => ({
  natio: {
    header: {},
    une: {},
    items: [],
    blocks: [],
    pagination: {},
    page: [],
    children: null
  },

  locale: {
    header: {},
    une: {},
    items: [],
    blocks: [],
    radio: {},
    pagination: {},
    children: null
  },

  metatag: {},
});

export const mutations = {
  //Page catégorie nationale
  setNatioPage(state, page) {
    state.natio.page = page;
  },
  setNatioHeader(state, header) {
    state.natio.header = header;
  },
  setNatioUne(state, une) {
    state.natio.une = une;
  },
  setNatioOrder(state, page) {
    state.natio.order = page;
  },
  setNatioBlocks(state, blocks) {
    state.natio.blocks = blocks;
  },
  setNatioPagination(state, pagination) {
    state.natio.pagination = pagination;
  },
  setNatioItems(state, items) {
    state.natio.items = items;
  },
  setNatioChildren(state, children) {
    state.natio.children = children
  },
  addNatioItems(state, items) {
    state.natio.items = items;
  },
  resetNatio(state, natio) {
    state.natio = natio;
  },

  // Page catégorie locale
  setLocaleHeader(state, header) {
    state.locale.header = header;
  },
  setLocalePagination(state, pagination) {
    state.locale.pagination = pagination;
  },
  setLocaleItems(state, items) {
    state.locale.items = items;
  },
  addLocaleItems(state, items) {
    state.locale.items = items;
  },
  setLocalChildren(state, children) {
    state.locale.children = children
  },
  resetLocale(state, locale) {
    state.locale = locale;
  },

  // Métatags
  setMetatag(state, metatag) {
    state.metatag = metatag;
  },
};

export const actions = {
  // Page catégorie nationale
  setNatioFirstPage({ commit }, params) {
    commit("setNatioHeader", {
      id: params.res.id,
      type: params.res.type,
      name: params.res.name,
      description: params.res.description,
      texte_seo: params.res.texte_seo ?? null,
      alias: params.res.alias,
    });
    commit("setNatioUne", params.res.une);
    commit("setNatioBlocks", params.res.blocks);
    commit("setNatioItems", params.res.content);
    commit("setNatioPagination", params.res.pagination);
    commit("setNatioChildren", params.res.children)
  },
  async setOtherNatioPage({ commit }, params) {
    await this.$nodeRepository
      .getByUrl(params.path, { page: params.page_id })
      .then((res) => {
        commit("setNatioHeader", {
          id: res.id,
          type: res.type,
          name: res.name,
          description: res.description,
          texte_seo: res.texte_seo ?? null,
          alias: res.alias,
        });

        let newItemsList = this.state.category.natio.items.concat(res.content);

        commit("addNatioItems", newItemsList);
        commit("setNatioPagination", res.pagination);
      })
      .catch((e) => {
        throw new Error('API call to {setOtherNatioPage} failed :' + e)
      });
  },
  setOrder({ commit }, params) {
    commit("setNatioPage", params.value);
  },

  resetLocale({ commit }) {
    commit("resetLocale", {
      header: {},
      une: {},
      items: [],
      blocks: [],
      pagination: {},
    });
  },
  resetNatio({ commit }) {
    commit("resetNatio", {
      header: {},
      une: {},
      items: [],
      blocks: [],
      pagination: {},
      page: [],
      children: null
    });
  },

  // Page catégorie locale
  setLocaleFirstPage({ commit }, params) {
    commit("setLocaleHeader", {
      id: params.res.id,
      type: params.res.type,
      name: params.res.name,
      description: params.res.description,
      texte_seo: params.res.texte_seo ?? null,
      alias: params.res.alias,
      radio: params.res.radio ?? null, // TODO : Radio est toujours null. Revoir la récupération de la radio dans le fetch de l'index
      children: params.children ? true : false
    });
    commit("setLocaleItems", params.res.content);
    commit("setLocalePagination", params.res.pagination);
  },

  async setOtherLocalePage({ commit }, params) {
    await this.$nodeRepository
      .getByUrl(params.path, { page: params.page_id, radio: params.radio })
      .then((res) => {
        commit("setLocaleHeader", {
          id: res.id,
          type: res.type,
          name: res.name,
          description: res.description,
          texte_seo: res.texte_seo ?? null,
          alias: res.alias,
          radio: res.radio
        });

        let newItemsList = this.state.category.locale.items.concat(res.content);

        commit("addLocaleItems", newItemsList);
        commit("setLocalePagination", res.pagination);
      })
      .catch((e) => {
        throw new Error('API call to {setOtherLocalePage} failed' + e);
      });
  },
};
