let restParams =  {
  _format: "json"
}

// API Repository
export default $axios => resource => ({

  getToken() {
    return $axios.$get(`${resource}/session/token`, {
      params: {
        _format: "json"
      }
    })
  },

  /**
   *
   * Allow to set params depending on type of params
   *
   * @param params
   * @returns params object for request
   */
  setRadioParams(params) {
    let newParams
    if (typeof (params) === "number" || typeof (params) === "string") {
      newParams = {
        _format: "json",
        'radio[0]': params
      }
    } else if (typeof (params) === "object" && params !== null) {
      newParams = {
        _format: "json",
        'radio[0]': params[0],
        'radio[1]': params[1]
      }
    } else {
      newParams = {
        _format: "json"
      }
    }
    return newParams
  },

  get() {
    let params = this.setRadioParams([])
    return $axios.$get(`${resource}`, {
      params: params
    })
  },

  getByPageNumber(number, radioIds) {
    let params = this.setRadioParams(radioIds)
    return $axios.$get(`${resource}?page=${number}`, {
      params: params
    })
  },

  getById(string) {
    let params = this.setRadioParams([])
    return $axios.$get(`${resource}/${string}`, {
      params: params
    })
  },

  getByIdWithoutParams(string) {
    return $axios.$get(`${resource}/${string}`)
  },

  getByIdAndPage(slug, number, radioIds) {
    let params = this.setRadioParams(radioIds)
    return $axios.$get(`${resource}/${slug}?page=${number}`, {
      params: params
    })
  },

  getByIdWithMeta(id) {
    let params = this.setRadioParams([])
    return $axios.$get(`${resource}/${id}`, {params: params})
      .then((res) => {
        if (res.metatag) {
          let jsonld = {
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "@" + res.metatag.value.schema_article_type,
                "headline": res.metatag.value.schema_article_headline,
                "name": res.metatag.value.schema_article_name,
                "description": res.metatag.value.schema_article_description,
                "image": res.metatag.value.schema_article_image,
                "datePublished": res.metatag.value.schema_article_date_published,
                "dateModified": res.metatag.value.schema_article_date_modified,
                "isAccessibleForFree": res.metatag.value.schema_article_is_accessible_for_free,
                "author":res.metatag.value.schema_article_author_0
              }
            ]
          }
          return {
            result: res,
            jsonld: jsonld
          }
        }
      })
  },

  getByUrl(url, param) {
    let restParams = {
      _format: "json"
    }

    if (typeof (param) !== "undefined") {
      restParams = {
        ...restParams,
        ...param
      }
    }
    return $axios.$get(`/${url}`, {
      params: restParams
    })
  },

  getByIdWithParam(param) {
    let restParams = {
      _format: "json"
    }

    if (typeof (param.args) !== "undefined") {
      let args = param.args

      restParams = {
        ...restParams,
        ...args
      }
    }

    return $axios.$get(`${resource}/${param.id}`, {
      params: restParams
    })
  },

  submit(data) {
    return $axios.$post(`${resource}`, data, {params: restParams})
      .then(response => {
        if (typeof (response.sid)) {
          return response
        }
      })
      .catch((error) => {
        return error.response
      })
  },

  submitFile(file) {
    let formData = new FormData();
    formData.append('file', file);

    return $axios.post('https://rcf-drupal-dev.datasolution.site/file/upload/webform/partners/joindre_des_documents?_format=json', formData, {
      headers: {
        'Content-Type': 'application/octet-stream',
        'content-disposition': 'file; .pdf',
      }
    }).then(response => {
      //console.log(response)
    })
  },

  getByIdWithQueryParam(param) {
    let restParams = {
      _format: "json"
    }

    if (typeof (param.args) !== "undefined") {
      let args = param.args

      restParams = {
        ...restParams,
        ...args
      }
    }

    return $axios.$get(`${resource}/${param.id}`, {
      params: restParams
    })
  },

  getByFullUrl(url, params) {
    return $axios.$get(`${url}`, {
      params: params
    })
  },

  deleteEntity(entity_name, entity_id) {
    return $axios.$delete(`${entity_name}/${entity_id}`, {
      _format: "json"
    })
  },

  postEntity(entity_name, data) {
    let params = {
      _format: "json"
    }

    return $axios.$post(`entity/${entity_name}`, data, {
      params: params
    }).then(response => {
      if(typeof (response)) {
        return response
      }
    }).catch(error => {
      console.log(error)
    })
  },

  addFlag(data) {
    return $axios.$post(`${resource}`, data, {
      _format: "json"
    }).catch(error => {
      console.log(error)
    })
  },

  createBlockedUser(sub) {
    return $axios.$get(`${resource}/rest_user/create/${sub}?refreshToken=${Math.random()}`, {
      _format: "json"
    }).catch(error => {
      console.log(error)
    })
  },

  postTrackingReplay(data) {
    return $axios.$post(`${resource}`, data, {
      params: restParams,
    }).catch(error => {
      console.log(error)
    })
  },
  patchTrackingReplay(data, trackerId) {
    let id = trackerId.toString()
    return $axios.$patch(`${resource}/${id}`, data, {
      params: restParams,
    }).catch(error => {
      console.log(error)
    })
  },
  postByData(data, token) {
    let params = {
      _format: "json"
    }

    return $axios.$post(`entity/${entity_name}`, data, {
      params: params
    }).then(response => {
      if (typeof (response)) {
        return response
      }
    }).catch(error => {
      console.log(error)
    })
  },

  searchShows(search_text, radioIds) {
    let params = this.setRadioParams(radioIds)
    return $axios.$get(`/search/shows?title=${search_text}`, {
      params: params,
    })
  }
})
