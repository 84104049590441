
import Vue from 'vue';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'Modal',
  data() {
    return {
      class: null
    };
  },
  computed: {
    ...mapState('modal', {
      visible: state => state.modalVisible,
      modalComponent: state => state.modalComponent,
      modalClass: state => state.modalClass,
      closeOnClickOutside: state => state.closeOnClickOutside
    }),
    stepComponent() {
      return () => import(`@/components/modal/${this.modalComponent}`);
    }
  },
  watch: {
    modalComponent(componentName) {
      if (!componentName) return;
      Vue.component(componentName, () => import(`@/components/modal/${componentName}`));
      this.component = componentName;
    },
    modalClass(className) {
      if (!className) return;
      this.class = className;
    }
  },
  mounted() {
    const escapeHandler = (e) => {
      if (e.key === 'Escape' && this.visible) {
        this.hideModal();
      }
    };

    document.addEventListener('keydown', escapeHandler);
    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', escapeHandler);
    });
  },
  methods: {
    ...mapMutations({
      hideModal: 'modal/hideModal'
    }),
    hideModalOnClickOutside() {
      if (this.closeOnClickOutside)
        this.hideModal();
    }
  },
}
