export const state = () => ({
  node: [],
  fields: [],
  partnersFields: [],
  confirmation_message: null,
  di_radio: null,
});

export const mutations = {
  setNode(state, node) {
    state.node = node;
  },
  setFields(state, fields) {
    state.fields = fields;
  },
  setPartnersFields(state, fields) {
    state.partnersFields = fields;
  },
  setMessage(state, message) {
    state.confirmation_message = message;
  },
  setRadio(state, radios) {
    state.di_radio = radios;
  },
};

export const actions = {
  async getFormNode({ commit, dispatch }, url) {
    let node = await this.$webformRepository.getByUrl(url);
    commit("setNode", node);

    if (typeof this.$cookies.get("cookie") !== "undefined") {
      dispatch("radio/getRadio", this.$cookies.get("cookie").radioTid, {root:true});
    }
  },
  async getFields({ commit }, param) {
    let fields = await this.$webformRepository.getByIdWithParam(param);
    commit("setFields", fields);
  },
  async getPartnersFields({ commit }, param) {
    let fields = await this.$webformRepository.getByIdWithParam(param);

    commit("setPartnersFields", fields);
  },
  async submit({ commit }, data) {
    console.log(data.form)
    let form = data.form;
    
    let dataElements = {
      webform_id: data.webform_id,
    };

    form.elements.forEach(function (element) {
      let value = element.value;
      let name = element.getAttribute("name");
      let type = element.getAttribute("type");

      if (type === "checkbox") {
        name = element.getAttribute("name").replace("newsletter-pop", "");

        if (element.checked) {
          value = 1;
        } else {
          value = 0;
        }
      }

      if (
        value !== null &&
        value !== "" &&
        value !== undefined &&
        name !== null &&
        name !== "" &&
        name !== undefined
      ) {
        dataElements = {
          ...dataElements,
          [name]: value,
        };
      }
    });
    if (Object.keys(dataElements).length > 1) {
      return this.$webformSubmitRepository.submit(dataElements).then((response) => {
        if (typeof response.sid !== "undefined") {
          this.$webformRepository
            .getByUrl("webform/" + dataElements.webform_id)
            .then((webform) => {
              if (typeof webform.settings.confirmation_message) {
                commit("setMessage", webform.settings.confirmation_message);
              }
            }).catch((e) => {
              commit("setMessage", "Erreur");
            })
        } else if (typeof response.data !== "undefined") {
          let msg = "";
          Object.keys(response.data.error).forEach((key) => {
            let label = document
              .querySelectorAll('label[for="' + key + '"]')[0]
              .innerText.replace(" *", "");
            msg += label + " : " + response.data.error[key] + "<br/>";
          });
          commit("setMessage", msg);
          const y = form.getBoundingClientRect().top + window.pageYOffset - 100;
          window.scrollTo({ top: y, behavior: "smooth" });
        }
        return response
      });
    }
  },

  async submitFile({ commit }, file) {
    await this.$defaultRepository.submitFile(file);
  },

  async recaptchaInit() {
    try {
      await this.$recaptcha.init();
    } catch (e) {
      console.error(e);
    }
  },

  async submitWishCard({ commit }, data) {
    await this.$saintRepository.submit(data);
  },

  async submitPrayerForm({ commit }, data) {
    await this.$webformSubmitRepository.submit(data);
  },

  async submitLegsForm({ commit }, data) {
    try {
      const response = await this.$webformSubmitRepository.submit(data);
      return response
    } catch (error) {
      throw error
    }
  },
};
