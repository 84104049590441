const https = require('https');

export default function ({ $axios, $config }, inject) {
  // Create a custom axios instance
  const proxyMailing = $axios.create({
    baseURL: $config.proxyMailingUrl,
    httpsAgent: new https.Agent({  
      rejectUnauthorized: false
    })
  })

  // Inject to context as $proxyMailing
  inject('proxyMailing', proxyMailing)
}