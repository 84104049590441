export const state = () => ({
    article : null,
    loaded: false
   });
   
   export const mutations = {
     setArticle(state, article) {
         state.article = article
     },
     setLoaded(state, loaded) {
         state.loaded = loaded
     }
   };
   
   export const actions = {
     async getArticle({commit},article) {
       try{
        commit("setLoaded", false);
          let art = await this.$articlePreviewRepository.getByUrl("preview/"+article.url);
          commit("setArticle", art);
          commit("setLoaded", true);
       } catch(e) {
          throw new Error(e.message);
       }
     }
   };
 