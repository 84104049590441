import { Amplify, Hub } from 'aws-amplify'

export default async ({ $req, $config }) => {
  const config = {
    Auth: {
      region: $config.cognitoRegion,
      userPoolId: $config.cognitoPoolId,
      userPoolWebClientId: $config.cognitoClientId,
      mandatorySignIn: false,
      authenticationFlowType: "USER_PASSWORD_AUTH",
      oauth: {
        redirectSignOut: `https://rcf.fr`
      },
      cookieStorage: {
        domain: $config.cookieDomain, //'localhost',
        path: '/',
        expires: 30,
        secure: $config.cookieSecure //false
      },
    }
  }

  Amplify.configure({
    ...config,
  });

}