
import {Hub} from 'aws-amplify'
import PageTitle from '../components/elements/PageTitle.vue'
export default {
  props: ["error"],
  layout: "error",
  components: { PageTitle },
  head() {
    let customMeta;
    if (this.error.statusCode == 403) {
      customMeta = {
        "metatag": {
          "value": {
            "title": "Accès refusé | RCF",
            "description": "Accès non autorisé"
          }
        }
      }
    } else {
      customMeta = {
        "metatag": {
          "value": {
            "title": "Page non trouvée | RCF",
            "description": "Si le problème persiste, vous pouvez obtenir de l'aide sur le fonctionnement du site en contactant notre service auditeur"
          }
        }
      }
    }

    return this.$headService.buildHead(customMeta);
  },
  async middleware(ctx) {
    const gtmGlobalObject = ctx.$gtmService.getGlobalObject("Page non trouvée");
    ctx.$gtmService.push(gtmGlobalObject);
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    Hub.listen("auth", async (data) => {
      if (data.payload.event === 'signIn' && this.error.statusCode == 403) {
        // Refresh
        this.$router.go()
      }
    });
  },
  computed: {
    isProd() {
      return this.$config.baseURL === 'https://www.rcf.fr'
    }
  }
}
