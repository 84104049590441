export const state = () => ({
  list: [],
  content: []
})

export const mutations = {
  setFavorites(state, list) {
    state.list = list
  },
  setFavoritesContent(state, content) {
    state.content = content
  },
  removeFavorite(state, favorite) {
    state.list.splice(state.list.indexOf(favorite), 1);
  }
}

export const actions = {
  async getUserFavorites({commit}){
    try {
      if(this.$auth.sub != null) {
        let params = {
          'id': 'favorites/' + this.$auth.sub
        }
        let favorites = await this.$defaultRepository.getByIdWithParam(params)
        commit('setFavorites', favorites)
      }
    } catch(e) {
      commit('setFavorites', [])
    }
  },
  async getUserFavoritesContent({commit}, routeParams){
    try {
      if(this.$auth.sub != null) {
        let params = {
          'id': 'favorites/content/' + this.$auth.sub
        }

        if(typeof (routeParams.page_id) !== "undefined") {
          let args = {
            'page': routeParams.page_id
          }

          params = {
            ...params,
            'args' : args
          }
        }
        
          let favorites = await this.$defaultRepository.getByIdWithParam(params)
          commit('setFavoritesContent', favorites)
      } 
    }catch(e) { 
      commit('setFavoritesContent', [])
    }
  },
  async add({commit}, show_id) {
    if(this.$auth.sub != null) {
      let data = {
        "wso2_uid": [{'value': this.$auth.sub}],
        "show_id": [{'target_id': show_id}]
      }

      let favorites = await this.$defaultRepository.postEntity('favorite', data)
      commit('setFavorites', favorites)
    }
  },
  async delete({commit}, show_id) {
    if(this.$auth.sub != null) {
      const index = this.state.favorites.list.findIndex( obj => obj.show_id == show_id)
      const flag = this.state.favorites.list[index]

      await this.$defaultRepository.deleteEntity('favorite', flag.id)
      commit('removeFavorite', flag)
    }
  },
  async deleteUserFavorites({commit}){
    try {
      if(this.$auth.sub != null) {
        await this.$defaultRepository.deleteEntity('favorites', this.$auth.sub)
        commit('setFavorites', [])
      }
    } catch(e) {
      commit('setFavorites', [])
    }
  },
}
