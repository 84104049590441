export default (ctx) => resource => ({
  openLink(url, target, internal) {
    if (target == '_blank') {
      var newWindow = window.open(url, '_blank');
      newWindow.focus();
    } else if (!internal) {
      document.location.href = url;
    }
    else {
      ctx.redirect(url);
    }
  }
})