export const state = () => ({
  basic: [],
});

export const mutations = {
  SET(state, basic) {
    state.basic = basic;
  },
};

export const actions = {
  async get_basic_page({ commit }, params) {
    let basic = await this.$defaultRepository.getByIdWithMeta(params.slug);
    if (basic) commit("SET", basic.result);
  },
  getBasicPage({ commit }, params) {
    // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ', params.basicPage)
    if (params.basicPage) commit("SET", params.basicPage);
  },

  resetBasicPage({ commit }) {
    commit("SET", []);
  },
};
