export const state = () => ({
  field_settings: [],
  search_value: '',
  filters: {},
  query: {},
  aggs: {},
  sort: {}
})

export const mutations = {
  INIT(state, field_settings) {
    state.field_settings = field_settings
  },
  SET_VALUE(state, value) {
    state.search_value = value
  },
  SET_FILTERS(state, filters) {
    state.filters = filters
  },
  SET_QUERY(state, query) {
    state.query = query
  },
  SET_AGGS(state, aggs) {
    state.aggs = aggs
  },
  SET_SORT(state, sort) {
    state.sort = sort
  }
}

export const actions = {

  async init({ commit }) {
    let field_settings;
    field_settings = await this.$searchService.getFieldsBoosted()
    if (typeof(field_settings) !== "undefined"){
      commit('INIT', field_settings.field_settings)
    }
  },
  setValue({ commit }, value) {
    if(value !== null){
      commit('SET_VALUE', value)
    }
  },
  setFilters({ commit }, filters) {
    if(filters !== null){
      commit('SET_FILTERS', filters)
    }
  },
  setQuery({ commit }, query) {
    if(query !== null){
      commit('SET_QUERY', query)
    }
  },
  setAggs({ commit }, aggs) {
    if(aggs !== null){
      commit('SET_AGGS', aggs)
    }
  },
  setSort({ commit }, sort) {
    if(sort !== null){
      commit('SET_SORT', sort)
    }
  },
}
