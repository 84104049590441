
import { mapMutations } from "vuex";

export default {
  name: "Flags",
  props: ["nid", "display", "transparent"],
  data() {
    return {
      isHovered: false,
    };
  },
  methods: {
    ...mapMutations({
      showModal: "modal/showModal",
    }),
    flagged() {
      return (
        typeof this.$store.state.favorites.list !== "undefined" &&
        Object.keys(this.$store.state.favorites.list).length > 0 &&
        this.$store.state.favorites.list.find(
          (obj) => obj.show_id == this.$props.nid
        )
      );
    },
    updateFlag(method, nid) {
      this.$store
        .dispatch(`favorites/${method}`, nid)
        .then(() => {
          this.$emit("flag-updated");
          if (method == "add") {
            this.showModal({ componentName: "FlagsModal", data: "ajoutée" });
          } else {
            this.showModal({ componentName: "FlagsModal", data: "retirée" });
          }
        })
        .catch((e) => {
          this.showModal({
            componentName: "InfoModal",
            data: "Une erreur est survenue<br>lors de la suppression du favori.",
          });
        });
    },
    handleFlag(event) {
      event.stopImmediatePropagation()
  
      if (!this.$auth.isAuthenticated) {
        this.showModal({ componentName: "FlagsModal" });
      } else {
        if (this.flagged()) {
          this.updateFlag("delete", this.$props.nid);
        } else {
          this.updateFlag("add", this.$props.nid);
        }
      }
    },
    buttonNameAccessbility() {
      if (this.flagged()) {
        return "Retirer des favoris";
      } else {
        return "Ajouter aux favoris";
      }
    },
  },
};
