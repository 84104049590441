import https from 'https';
import dayjs from 'dayjs'

export default function ({ $axios }) {
  // doit être commenté en prod
  $axios.defaults.httpsAgent = new https.Agent({ rejectUnauthorized: false });

  $axios.onRequest(config => {})

  $axios.onResponse(response => {})

  $axios.onError(err => {
    if(process.server) {
      if (err.response) {
        const code = parseInt(err.response.status)
        switch (code) {
          case code >= 400:
            console.error(code + ' -- ' + dayjs(new Date()).format('DD-MM-YYYY HH:mm:ss') + ' - on url :' + err.config.url);
            break;
        }
      }
    }
  })
}
