export const state = () => ({
  folder: null,
  folders: [],
  allArticles: [],
  blockFolders: [],
  filter: undefined,
  pager: {},
});

export const mutations = {
  setFolder(state, folder) {
    state.folder = folder;
  },
  setFolders(state, folders) {
    state.folders = folders;
  },
  setAllArticles(state, articles) {
    state.allArticles = articles
  },
  addAllArticles(state, articles) {
    state.allArticles = [
      ...state.allArticles,
      ...articles
    ]
  },
  setBlockFolders(state, folders) {
    state.blockFolders = folders;
  },
  setFilter(state, filter) {
    state.filter = filter;
  },
  addFolders(state, folders) {
    state.folders.rows = [...state.folders.rows, ...folders];
  },
  setPager(state, pager) {
    state.pager = pager;
  },
  resetPager(state) {
    state.pager = {};
  },
  resetAllArticles(state) {
    state.allArticles = [];
  },
};

export const actions = {
  async getFolders({ commit }, alias) {
    let params = {};
    let url = alias;

    if (typeof alias.page_id !== "undefined") {
      url = alias.slug;
      params = {
        page: alias.page_id,
      };
    }

    try {
      let folders = await this.$nodeRepository.getByUrl(url, params);
      commit("setFolders", folders);
    } catch (e) {
      throw new Error('API call {setFolders} failed')
    }

  },
  async getFolder({ commit }, alias) {
    let params = {};
    let url = alias;

    if (typeof alias.page_id !== "undefined") {
      url = alias.slug;
      params = {
        page: alias.page_id,
      };
    }

    let folder = await this.$nodeRepository.getByUrl(url, params);
    commit("setFolder", folder.folder);
    commit("setBlockFolders", folder.related_folders)
    commit("resetPager");
    commit("resetAllArticles");
  },

  async getAllArticles({ commit, state }, filters) {

    const params = {
      page: filters?.page_id ?? 0,
      radio: state.filter
    };
    let articles = await this.$nodeRepository.getByUrl(`dossier/${state.folder.id}/articles`, params);

    if (!filters?.page_id || filters.page_id == 0) {
      commit("setAllArticles", articles.rows);
    }
    else {
      commit("addAllArticles", articles.rows);
    }   
    
    commit("setPager", articles.pager);

  },

  async getMoreFolders({ commit }, data) {
    let params = {};
    let url;
    if (typeof alias.page_id !== "undefined") {
      url = alias.slug;
      params = {
        page: alias.page_id,
      };
    }
    else {
      url = data
    }

    let folders = await this.$nodeRepository.getByUrl(url, params);
    commit("addFolders", folders.rows);
    commit("setPager", folders.pager);
  },
};
