let restParams =  {
  _format: "json",
}

// API Repository
export default (store, $axios, $config, route) => resource => ({

  async getFieldsBoosted() {
    return await $axios.$get(`/admin/config/search/search-api/index/`+$config.esIndexName, {
      params: restParams,
    })
  },
  async init(activeFacets){

    let searchIcon = document.querySelectorAll(".header-right-menu .icon-cross")[0]
    let searchBlock = document.getElementsByClassName('search-widget-active')[0]

    if (typeof(searchBlock) !== "undefined"){
      searchBlock.classList.remove('search-widget-active')
    }
    if (typeof(searchIcon) !== "undefined"){
      searchIcon.classList.remove('icon-cross')
      searchIcon.classList.add('icon-search')
    }

    await store.dispatch('search/init')
    store.dispatch('search/setFilters', {'filter': [{"bool": {"must":[]}}]})
    store.dispatch('search/setAggs', {'aggs': {}})
    store.dispatch('search/setSort', {"sort":["_score"]})
    store.dispatch('search/setQuery', {'query': {'bool': {}}})

    if(store.state.search.search_value !== ''){
      return this.submit(activeFacets)
    }
    else {
      return this.initFacets(activeFacets)
    }
  },
  async initFacets(activeFacets){

    let facets = []
    for(let label in activeFacets) {
      for (let key in activeFacets[label]){
        facets.push(this.buildFacet(activeFacets[label][key]))
      }
    }

    this.buildAggs(facets)
    return await $axios.post($config.esUrl + '/_search', store.state.search.aggs)
  },
  buildQuery(){
    let fields_to_search = []

    for (let field_setting in store.state.search.field_settings){

      if(store.state.search.field_settings[field_setting].type ==="text"){

        fields_to_search.push(field_setting + '^' + store.state.search.field_settings[field_setting].boost)
      }
    }

    let query = store.state.search.query

    Object.assign(query.query.bool,{
      'must': {
        'query_string': {
          'query': "*" + store.state.search.search_value + "*",
          'fields': fields_to_search,
          'default_operator': "OR"
        }
      },
    })
    Object.assign(query.query.bool, store.state.search.filters)
    Object.assign(query, store.state.search.sort)
    Object.assign(query, {
      "highlight": {
        "fields": {
          "*": {}
        }
      }
    })
    Object.assign(query, {"size":1000})
    Object.assign(query, store.state.search.aggs)
    store.dispatch('search/setQuery', query)
  },
  buildAggs(facets){
    let aggs = store.state.search.aggs
    for (let facet in facets){
      Object.assign(aggs.aggs, facets[facet]);
    }
    store.dispatch('search/setAggs', aggs)
  },
  buildFacet(field_name) {

    let terms = {
      [field_name]: {
        terms: {
          field: field_name
        }
      }
    }

    return terms
  },
  async submit(activeFacets) {

    let facets = []
    for(let label in activeFacets) {
      for (let key in activeFacets[label]){
        facets.push(this.buildFacet(activeFacets[label][key]))
      }
    }
    this.buildAggs(facets)
    this.buildQuery()

    return await $axios.post($config.esUrl + '/_search', store.state.search.query)
  },
  addFilter(field_name, field_value ){

    let filters = store.state.search.filters
    let exist = false

    let default_request = {
      "bool": {
        "should": []
      }
    }
    default_request.bool.should.push({"term":{[field_name]:field_value}})

    for (let filter in filters.filter[0].bool.must){
      if(filters.filter[0].bool.must[filter].bool.should[0].term[field_name] === field_value){
        filters.filter[0].bool.must.splice(filter, 1)
        exist = true
      }
    }
    if(!exist){
      filters.filter[0].bool.must.push(default_request)
    }
    store.dispatch('search/setFilters', filters)
  },
  removeFilter(field_name, field_value){

    let filters = store.state.search.filters
    for (let filter in filters.filter[0].bool.must){
      if(filters.filter[0].bool.must[filter].bool.should[0].term[field_name] === field_value){
        filters.filter[0].bool.must.splice(filter, 1)
      }
    }
    store.dispatch('search/setFilters', filters)
  },
  sortResults(sort_type) {
    if (sort_type === "date"){
      store.dispatch('search/setSort', {"sort":[{"changed":"desc"}]})
    }
    else {
      store.dispatch('search/setSort', {"sort":["_score"]})
    }
  },
})
