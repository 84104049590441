export const state = () => ({
    years: [],
    months: [],
    articles: []
})
export const mutations = {
  setYears(state, years) {
    state.years = years
  },
  setMonths(state, months) {
    state.months = months
  }
  ,
  setArticles(state, articles) {
    state.articles = articles
  }
}

export const actions = {
  async getArchives({commit}){
    let content = await this.$defaultRepository.getByUrl('archives/')
    let years=[]
    for (let year in content) {
      years.push(year)
    }
    years.sort((a, b) => b - a)
    commit('setYears', years)
    commit('setMonths', content)
  },
  async getArticles({commit}, params){
    let content = await this.$defaultRepository.getByUrl('archives/' + params.year + '/' + params.month + '/' + params.day)
    commit('setArticles', content)
  }
}