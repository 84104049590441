export const state = () => ({
  aroundme: [],
  aroundmeAlias: '',
  aroundMePage: {},
  metatag: {},
})

export const mutations = {
  setAroundme(state, aroundme) {
    state.aroundme = aroundme
  },
  setOtherAroundme(state, aroundme) {
    state.otherAroundme = aroundme
  },
  setAroundmeAlias(state, aroundmeAlias) {
    state.aroundmeAlias = aroundmeAlias
  },
  setAroundMePage(state, data) {
    state.aroundMePage = data
  },
  setMetatag(state, metatag) {
    state.metatag = metatag;
  },
}

export const actions = {
  setAlias({ commit }, alias) {
    commit('setAroundmeAlias', alias)
  },
  async getAroundme({ commit }, alias) {
    let aroundme = await this.$nodeRepository.getByFullUrl(alias,  {_format: "json"})
    commit('setAroundme', aroundme)
  },

  /**
   * Get aroundMe page blocks
   */
  async getAroundmePage({ commit }, params) {
    commit('setAroundMePage', params.aroundMePage)
  },

  resetAroundMePage({commit}) {
    commit('setAroundMePage', null)
  }
}
