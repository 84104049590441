import dayjs from "dayjs"
import 'dayjs/locale/fr'

export default () => resource => ({

  secondsToHms(number) {
    number = Number(number);

    let hours = Math.floor(number / 3600);
    let minutes = Math.floor(number % 3600 / 60);
    let seconds = Math.floor(number % 3600 % 60);

    let hours_to_display, minutes_to_display, seconds_to_display
    (hours < 10 && hours !== 0)? hours = "0" + hours:'';
    (minutes < 10)? minutes = "0" + minutes : '';
    (seconds < 10)? seconds = "0" + seconds : '';

    if (hours !== 0 ){
      return hours + ':' + minutes + ':' + seconds;
    }
    else {
      return minutes + ':' + seconds;
    }

  },

  msToMin(number) {
    number = Number(number);
    let minutes = Math.round(number / 60000);
    return minutes + ' min';
  },

  toDate(dateTime, format){
    return dayjs(dateTime).locale('fr').format(format)
  },

  toDateFromUnix(dateTime, format){
    return dayjs.unix(dateTime).locale('fr').format(format)
  },

  addDays(dateTime, nbDays){
    return dayjs(dateTime).add(nbDays, 'days').locale('fr').toDate();
  },

  getDurationInSeconds(startDate, endDate) {
    return dayjs(endDate).diff(dayjs(startDate), 'second')
  },

  today(format) {
    return dayjs().format(format)
  },
  getStartMonthAndYear() {
    let startDate = this.addDays(dayjs(), -90)
    return {month: dayjs(startDate).month(), year: dayjs(startDate).year() }
  },
})