export default () => resource => ({
  getMessage(error) {
    let message = ''
    switch (error.code) {
      case "LimitExceededException":
        message = 'Vous avez atteint le nombre de tentatives autorisé. Veuillez réessayer dans quelques temps.'
        break;
      case "NotAuthorizedException":
        message = 'Les identifiants saisis sont incorrects, veuillez rééssayer.'
        break;
      case 'CodeMismatchException':
        message = 'Code incorrect, veuillez réessayer.'
        break;
      case 'ExpiredCodeException':
        message = "Le code fourni est obsolète, un nouveau code vient d'être envoyé."
        break;
      case 'UsernameExistsException':
        message = "Un compte existe déjà avec cette adresse email.<br>Si vous ne vous souvenez pas de votre mot de passe, cliquez sur <a href='/mon-compte/forgotPassword' class='underline'>mot de passe oublié</a>."
        break;
      default:
        message = error.message + ' (' + error.code + ')';
    }

    return message
  }
})