export default (cookies, store, $config, $auth) => resource => ({

  setCookie() {
    store.commit('modal/showModal', {componentName:'SelectRegionModal'})
  },
  initListenerCounter() {
    if(!cookies.get('cookieListenerCounter')) {
      cookies.set(
        "cookieListenerCounter",
        {
          ListenerNb : 0,
          ListenerNbConnected : 0,
          oldUser: false,
          episodesDigas: {},
          endPlay : false
        },
        {
          path: '/',
          maxAge: 60 * 60 * 24 * 30
        }
      )
    }
  },
  setListenerCounter(listenerCounter, episodeDigas) {
    //listenerCounter.oldUser = true

    cookies.set(
      "cookieListenerCounter",
      listenerCounter,
      {
        path: '/',
        maxAge: 60 * 60 * 24 * 30
      }
    )

    if(!$auth.isAuthenticated && typeof (episodeDigas) !== 'undefined') {
      this.setListenerCounterModal(listenerCounter, episodeDigas, false)
    }
  },
  setListenerCounterModal(listenerCounter, episodeDigas, ListenerNbLimit) {
    let ListenerCounterModal = document.querySelectorAll('.listener-counter')

    if(typeof (ListenerCounterModal) !== 'undefined' && typeof (ListenerCounterModal[0]) !== 'undefined') {
      let content = ListenerCounterModal[0].querySelectorAll('.modal-content')[0]
      let noneNotificationText = content.querySelectorAll('.none_notification_text')[0]
      let allNotificationText = content.querySelectorAll('.text')

      if(ListenerNbLimit) {
        if(typeof (allNotificationText) !== 'undefined') {
          allNotificationText[0].classList.remove('is-active')
        }

        noneNotificationText.classList.add('is-active')

        this.addListenerCounterModal()
        ListenerCounterModal[0].querySelectorAll('.modal')[0].classList.add('is-active')
      }
      else if(typeof (listenerCounter.episodesDigas[episodeDigas]) !== 'undefined'
        && listenerCounter.episodesDigas[episodeDigas].currentListener > $config.currentListener
        && listenerCounter.ListenerNb > 0) {

        if ($config.listenerMax - listenerCounter.ListenerNb == 1) {
          let singularNotificationText = content.querySelectorAll('.singular_notification_text')[0]
          singularNotificationText.innerHTML = singularNotificationText.innerHTML.replace(
            "%nb_listener", $config.listenerMax - listenerCounter.ListenerNb
          )

          if(typeof (allNotificationText) !== 'undefined') {
            allNotificationText[0].classList.remove('is-active')
          }

          singularNotificationText.classList.add('is-active')
        }
        else if ($config.listenerMax - listenerCounter.ListenerNb > 1) {
          let pluralNotificationText = content.querySelectorAll('.plural_notification_text')[0]

          if(typeof (pluralNotificationText) !== 'undefined') {
            pluralNotificationText.innerHTML = pluralNotificationText.innerHTML.replace(
              "%nb_listener", $config.listenerMax - listenerCounter.ListenerNb
            )

            if(typeof (allNotificationText) !== 'undefined') {
              allNotificationText[0].classList.remove('is-active')
            }

            pluralNotificationText.classList.add('is-active')
          }
        }
        else {
          if(typeof (allNotificationText) !== 'undefined') {
            allNotificationText[0].classList.remove('is-active')
          }
          noneNotificationText.classList.add('is-active')
        }

        this.addListenerCounterModal()
      }
    }
  },
  
  addListenerCounterModal() {
    let ListenerCounterModalBottom = 10

    let playerContainer = document.querySelectorAll('.player-container')
    let playerBottom = playerContainer[0].querySelectorAll('.player-bottom')
    let ListenerCounterModal = document.querySelectorAll('.listener-counter')

    if(typeof (playerBottom) !== 'undefined' && typeof (playerBottom[0]) !== 'undefined') {
      ListenerCounterModalBottom += playerBottom[0].offsetHeight
    }

    if(window.innerWidth <= 1024) {
      let menuFooter =  document.querySelectorAll('.footer .footer-menu-navigation')

      if(typeof (menuFooter) !== 'undefined') {
        ListenerCounterModalBottom += menuFooter[0].offsetHeight
      }
    }

    if(typeof (ListenerCounterModal) !== 'undefined') {
      ListenerCounterModal[0].style.bottom = ListenerCounterModalBottom +'px'
      ListenerCounterModal[0].classList.add('is-active')
    }
  },


  async setDonationPathway(listenerNb) {

    let hasPreRollsGlobal, hasPostRollsGlobal = false

    if(store.state.roll.preRollsGlobal !== null && Object.keys(store.state.roll.preRollsGlobal).length > 0) {
      hasPreRollsGlobal = true
    }

    if(store.state.roll.postRollsGlobal !== null && Object.keys(store.state.roll.postRollsGlobal).length > 0) {
      hasPostRollsGlobal = true
    }

    let listenerCounter = cookies.get('cookieListenerCounter')
    let paramsPreRoll, paramsPostRoll = {}
    let preRoll, postRoll = false
    let isRefresh = true
    let level = null

    if($auth.isAuthenticated && level !== null) {
      if(typeof (listenerNb) === 'undefined') {
        listenerNb = listenerCounter.ListenerNbConnected
        isRefresh = false
      }

      switch (listenerNb) {
        case 2:
          if(hasPreRollsGlobal) {
            preRoll = this.preparePreRollsGlobal()
          }
          else if(isRefresh || listenerCounter.endPlay) {
            preRoll = true
            paramsPreRoll = this.connectedparamsRoll('Pre-roll', level)

            if(!store.state.roll.preRollModal) {
              store.commit('roll/setPreRollModal')
            }

            if(!store.state.roll.hasPreRolls) {
              store.commit('roll/setHasPreRolls')
            }
          }

          if(hasPostRollsGlobal) {
            postRoll = this.preparePostRollsGlobal()
          }
          else {
            postRoll = true
            paramsPostRoll = this.connectedparamsRoll('Post-roll', level)

            if(!store.state.roll.postRollModal) {
              store.commit('roll/setPostRollModal')
            }
          }
          break;
        case 3:
          if(hasPreRollsGlobal) {
            preRoll = this.preparePreRollsGlobal()
          }
          else {
            if (isRefresh) {
              preRoll = false
              store.commit('roll/setPreRolls', null)

              if (store.state.roll.hasPreRolls) {
                store.commit('roll/setPreRollModal')
              }

              if (store.state.roll.preRollModal) {
                store.commit('roll/setHasPreRolls')
              }
            } else {
              preRoll = true
              paramsPreRoll = this.connectedparamsRoll('Pre-roll', level)

              if (!store.state.roll.hasPreRolls) {
                store.commit('roll/setPreRollModal')
              }

              if (!store.state.roll.preRollModal) {
                store.commit('roll/setHasPreRolls')
              }
            }
          }

          if(hasPostRollsGlobal) {
            postRoll = this.preparePostRollsGlobal()
          }
          else {
            postRoll = false
            store.commit('roll/setPostRolls', null)

            preRoll = false
            store.commit('roll/setPreRolls', null)

            if (store.state.roll.hasPreRolls) {
              store.commit('roll/setPreRollModal')
            }

            if (store.state.roll.preRollModal) {
              store.commit('roll/setHasPreRolls')
            }
          }
          break;
        case 4:
          if(hasPreRollsGlobal) {
            preRoll = this.preparePreRollsGlobal()
          }

          if(hasPostRollsGlobal) {
            postRoll = this.preparePostRollsGlobal()
          }
          else {
            postRoll = true
            paramsPostRoll = this.connectedparamsRoll('Post-roll', level)

            if(!store.state.roll.postRollModal) {
              store.commit('roll/setPostRollModal')
            }
          }
          break;
        case 5:
          if(hasPreRollsGlobal) {
            preRoll = this.preparePreRollsGlobal()
          }
          else {
            if(isRefresh || listenerCounter.endPlay) {
              preRoll = true
              paramsPreRoll = this.connectedparamsRoll('Pre-roll', level)

              if(!store.state.roll.hasPreRolls) {
                store.commit('roll/setHasPreRolls')
              }

              if(!store.state.roll.preRollModal) {
                store.commit('roll/setPreRollModal')
              }
            }
          }

          if(hasPostRollsGlobal) {
            postRoll = this.preparePostRollsGlobal()
          }
          else {
            postRoll = false
            store.commit('roll/setPostRolls', null)

            if (store.state.roll.postRollModal) {
              store.commit('roll/setPostRollModal')
            }
          }

          break;
        case 6:
          if(hasPreRollsGlobal) {
            preRoll = this.preparePreRollsGlobal()
          }
          else {
            if (isRefresh) {
              preRoll = false
              store.commit('roll/setPreRolls', null)

              if(store.state.roll.hasPreRolls) {
                store.commit('roll/setPreRollModal')
              }

              if(store.state.roll.preRollModal) {
                store.commit('roll/setHasPreRolls')
              }
            }
            else {
              preRoll = true
              paramsPreRoll = this.connectedparamsRoll('Pre-roll', level)

              if (!store.state.roll.hasPreRolls) {
                store.commit('roll/setPreRollModal')
              }

              if (!store.state.roll.preRollModal) {
                store.commit('roll/setHasPreRolls')
              }
            }
          }

          if(hasPostRollsGlobal) {
            postRoll = this.preparePostRollsGlobal()
          }
          else {
            postRoll = true
            paramsPostRoll = this.connectedparamsRoll('Post-roll', level)

            if (!store.state.roll.postRollModal) {
              store.commit('roll/setPostRollModal')
            }

            preRoll = false
            store.commit('roll/setPreRolls', null)

            if(store.state.roll.hasPreRolls) {
              store.commit('roll/setPreRollModal')
            }

            if(store.state.roll.preRollModal) {
              store.commit('roll/setHasPreRolls')
            }
          }
          break;
      }

      if(preRoll) {
        store.dispatch('roll/getPreRolls', paramsPreRoll)
      }

      if(postRoll) {
        store.dispatch('roll/getPostRolls', paramsPostRoll)
      }
    }
    else if(!$auth.isAuthenticated) {
      if(typeof (listenerNb) === 'undefined') {
        listenerNb = listenerCounter.ListenerNb
        isRefresh = false
      }

      switch (listenerNb) {
        case 0:         
          preRoll = true
          paramsPreRoll = this.anonymousparamsPreRoll('firstListening')

          if(!store.state.roll.hasPreRolls) {
            store.commit('roll/setHasPreRolls')
          }
            
          if(hasPostRollsGlobal) {
            postRoll = this.preparePostRollsGlobal()
          }
          break;
        case 1:
          if(hasPreRollsGlobal) {
            preRoll = this.preparePreRollsGlobal()
          }
          else {
            preRoll = false
            store.commit('roll/setPreRolls', null)
            store.commit('roll/setHasPreRolls', false)
          }

          if (hasPostRollsGlobal) {
            postRoll = this.preparePostRollsGlobal()
          } 
          else {
            postRoll = false
            hasPostRollsGlobal = false
            store.commit('roll/setHasPostRolls', false)
          } 
          break;
        case 2:
          if(hasPreRollsGlobal) {
            preRoll = this.preparePreRollsGlobal()
          }

          postRoll = true
          hasPostRollsGlobal = false
          paramsPostRoll = this.anonymousparamsPostRoll('thirdListening')

          if(!store.state.roll.hasPostRolls) {
            store.commit('roll/setHasPostRolls')
          }
          break;
        case 3:
          preRoll = false
          store.commit('roll/setPreRolls', null)
          store.commit('roll/setHasPreRolls', false)
          paramsPreRoll = this.anonymousparamsPreRoll('fifthListening')

          if (hasPostRollsGlobal) {
            postRoll = this.preparePostRollsGlobal()
            store.commit('roll/setPostRolls', null)
          } 
          else {
            postRoll = false
            hasPostRollsGlobal = false
            store.commit('roll/setPostRolls', null)
            store.commit('roll/setHasPostRolls', false)
          } 
          break;
        case 4:
          preRoll = true
          paramsPreRoll = this.anonymousparamsPreRoll('fifthListening')

          if(!store.state.roll.hasPreRolls) {
            store.commit('roll/setHasPreRolls')
          }

          postRoll = false
          store.commit('roll/setPostRolls', null)

          break;
        case 5:
          preRoll = false
          store.commit('roll/setPreRolls', null)
          store.commit('roll/setHasPreRolls', false)
        
          postRoll = false
          store.commit('roll/setPostRolls', null)
          store.commit('roll/setHasPostRolls', false)

          break;
      }

      if(preRoll) {
        await store.dispatch('roll/getPreRolls', paramsPreRoll)
      }

      if(postRoll) {
        await store.dispatch('roll/getPostRolls', paramsPostRoll)
      }
    }
  },

  preparePreRollsGlobal() {
    let preRollsGlobal = store.state.roll.preRollsGlobal
    store.commit('roll/setPreRolls', preRollsGlobal)

    if(!store.state.roll.preRollModal
      && (preRollsGlobal[0].field_roll_pop_in_donation_deskt.length > 0 || preRollsGlobal[0].field_roll_pop_in_donation_mobil.length > 0)) {
      store.commit('roll/setPreRollModal')
    }

    if(!store.state.roll.hasPreRolls) {
      store.commit('roll/setHasPreRolls')
    }

    return false
  },

  preparePostRollsGlobal() {
    let postRollsGlobal = store.state.roll.postRollsGlobal
    store.commit('roll/setPostRollsGlobal', postRollsGlobal)

    if(!store.state.roll.postRollModal
      && (postRollsGlobal[0].field_roll_pop_in_donation_deskt.length > 0 || postRollsGlobal[0].field_roll_pop_in_donation_mobil.length > 0)) {

      store.commit('roll/setPostRollModal')
    }

    return false
  },

  anonymousparamsPreRoll(listenerNb) {
    let paramsPreRoll = {}
    let rollTargetUsers = 'Anonyme'

    switch (listenerNb) {
      case 'firstListening':
        paramsPreRoll = {
          'id' : 'rolls',
          'args' : {
            'roll_type':'Pre-roll',
            'roll_use': 'Parcours Dons Utilisateurs anonymes',
            'roll_target_users': rollTargetUsers,
            'roll_number_of_plays': listenerNb
          }
        }
        break;
      case 'secondListening':
        paramsPreRoll = {
          'id' : 'rolls',
          'args' : {
            'roll_type':'Pre-roll',
            'roll_use': 'Parcours Dons Utilisateurs anonymes',
            'roll_target_users': rollTargetUsers,
            'roll_number_of_plays': listenerNb
          }
        }
        break;
      case 'thirdListening':
        paramsPreRoll = {
          'id' : 'rolls',
          'args' : {
            'roll_type': 'Pre-roll',
            'roll_use': 'Appel au Don générique',
            'roll_target_users': rollTargetUsers,
            'roll_number_of_plays': listenerNb
          }
        }
        break;
      case 'fifthListening':
        paramsPreRoll = {
          'id' : 'rolls',
          'args' : {
            'roll_type': 'Pre-roll',
            'roll_use': 'Parcours Dons Utilisateurs anonymes',
            'roll_target_users': rollTargetUsers,
            'roll_number_of_plays': listenerNb
          }
        }
        break;
    }
    return paramsPreRoll
  },

  anonymousparamsPostRoll(listenerNb) {
    let paramsPostRoll = {}
    let rollTargetUsers = 'Anonyme'

    switch (listenerNb) {
      case 'secondListening':
        paramsPostRoll = {
          'id' : 'rolls',
          'args' : {
            'roll_type':'Post-roll',
            'roll_use': 'Appel au Don générique',
            'roll_target_users': rollTargetUsers,
            'roll_number_of_plays': listenerNb
          }
        }
        break;
      case 'thirdListening':
        paramsPostRoll = {
          'id' : 'rolls',
          'args' : {
            'roll_type':'Post-roll',
            'roll_use': 'Parcours Dons Utilisateurs anonymes',
            'roll_target_users': rollTargetUsers,
            'roll_number_of_plays': listenerNb
          }
        }
        break;
      case 'fourthListening':
        paramsPostRoll = {
          'id' : 'rolls',
          'args' : {
            'roll_type':'Post-roll',
            'roll_use': 'Appel au Don générique',
            'roll_target_users': rollTargetUsers,
            'roll_number_of_plays': listenerNb
          }
        }
        break;
    }

    return paramsPostRoll
  },

  connectedparamsRoll(type, level) {
    let paramsRoll = {
      'id' : 'rolls',
      'args' : {
        'roll_type': type,
        'roll_use': 'Appel au Don générique',
        'roll_target_users': 'Connecté',
        'roll_level': level
      }
    }

    return paramsRoll
  },
  SetPrehomeCounter(prehomeId, data) {
    let cookieName = 'cookiePrehomeCounter-'+prehomeId
    if(typeof (cookies.get(cookieName)) === 'undefined' && typeof (data) == 'undefined') {
      cookies.set(
        cookieName,
        {
          nbDisplaysToday : 0,
          nbDisplaysInCampaign : 0,
          lastDisplayDate: ''
        },
        {
          path: '/',
          maxAge: 60 * 60 * 24 * 60 // 2 mois
        }
      )
    }
    else if (typeof (data) !== 'undefined'){
      cookies.set(
        cookieName,
        data,
        {
          path: '/',
          maxAge: 60 * 60 * 24 * 60 // 2 mois
        }
      )
    }
    return cookies.get(cookieName);
  },
  LaunchPrehome(prehome){
    // Init prehome cookie if not set
    let prehomeCounter = this.SetPrehomeCounter(prehome.id)

    // Reset display per date if date change
    var d = new Date();
    var currentDate = d.getFullYear().toString()+("0" + (d.getMonth() + 1)).slice(-2)+("0" + d.getDate()).slice(-2);
    if (prehomeCounter.lastDisplayDate == ""
        || prehomeCounter.lastDisplayDate !== currentDate) {
      prehomeCounter.nbDisplaysToday = 0;
      prehomeCounter.lastDisplayDate = currentDate;
    }

    // Display prehome if counters are OK
    if (prehome.nbDisplaysPerDay == ""
        || (prehomeCounter.nbDisplaysToday < prehome.nbDisplaysPerDay && prehomeCounter.nbDisplaysInCampaign < prehome.nbDisplaysPerCampaign)) {
      if(prehome.type && prehome.type=="radio_don") {
        store.commit('modal/showModal', {componentName:'PrehomeRadioDonModal', data:prehome})
      } else {
        store.commit('modal/showModal', {componentName:'PrehomeModal', data:prehome})
      }
      prehomeCounter.nbDisplaysToday += 1;
      prehomeCounter.nbDisplaysInCampaign += 1;

      // Update displays counters
      this.SetPrehomeCounter(prehome.id, prehomeCounter)
    }
  }
})
