import dayjs from "dayjs";

export const state = () => ({
  infos: {},
  civilities: [],
  countries: [],
  newsletter_lists: [],
  marketing_list_id: null,
  leg_list_id: null,
})

export const mutations = {
  setInfos(state, infos) {
    state.infos = infos
  },
  setCivilities(state, civilities) {
    state.civilities = civilities
  },
  setCountries(state, countries) {
    state.countries = countries
  },
  setNewsletterLists(state, lists) {
    state.newsletter_lists = lists
  },
  setMarketingListId(state, id) {
    state.marketing_list_id = id
  },
  setLegListId(state, id) {
    state.leg_list_id = id
  }
}

export const actions = {
  async getCivilities({commit}){
    let content = await this.$proxyContact.$get('/civilities')
    let sortable = [];
    for (var key in content) {
      sortable.push({id:key, value:content[key]});
    }
    sortable.sort((a,b) => a.value.localeCompare(b.value))
    
    commit('setCivilities', sortable)
  },
  async getCountries({commit}){
    let content = await this.$proxyContact.$get('/countries', {
      params: {
        expanded: 'true'
      }
    })
    commit('setCountries', content)
  },
  async getInfos({commit}){
    const email = this.$cryptoUtils.encrypt(this.$auth.email, this.$config.middlewareToken)
    const content = await this.$proxyContact.$get(`/${email}`)
    content.birthdate = (content.birthdate.length > 0) ? dayjs(content.birthdate).format('YYYY-MM-DD') : ''
    commit('setInfos', content)
  },
  /**
   * @param {*} param0 
   * @param {*} contact { civilite, lastname, firstname, email, phone, birthdate, address: {line1, line2, line3, postalCode, city, country}}
   */
  async saveContact({commit}, contact) {
    const email = this.$cryptoUtils.encrypt(contact.email, this.$config.middlewareToken)
    const content = await this.$proxyContact.$post(`/${email}`, contact)
  },

  async updateEmail({}, {oldEmail, newEmail}) {
    return await this.$proxyContact.$patch('/email', {
      oldEmail: oldEmail,
      newEmail: newEmail
    })
  },

  async updateRadio({}, {email, radio}) {
    return await this.$proxyContact.$post('/updateRadio', {
      email: email,
      radio: radio
    })
  },

  async getNewsletterLists({commit}){
    let content = await this.$proxyMailing.$get('/news')
    let sortable = [];
    for (var key in content) {
      sortable.push({id:key, value:content[key].replace('Newsletter ', '')});
    }
    sortable.sort((a,b) => a.value.localeCompare(b.value))
    commit('setNewsletterLists', sortable)
  },
  async getMarketingListId({commit}){
    let content = await this.$proxyMailing.$get('/marketing')
    const keys = Object.keys(content)
    if (keys.length > 0){
      const id = keys[0]
      commit('setMarketingListId', id)
    }
  },
  async getLegListId({commit}){
    let content = await this.$proxyMailing.$get('/legs')
    const keys = Object.keys(content)
    if (keys.length > 0){
      const id = keys[0]
      commit('setLegListId', id)
    }
  },
  async deleteContact({}) {
    const email = this.$cryptoUtils.encrypt(this.$auth.email, this.$config.middlewareToken)
    return await this.$proxyContact.$delete(`/${email}`)
  }
}