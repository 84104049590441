export const state = () => ({
    id: null,
    show: [],
    episodes: [],
    shows: [],
    episode: [],
    nationalPrayers: [],
    localPrayers: [],
    todaySaint: null,
    saintEpisode: null,
    lastSaintsEpisodes: [],
    pager: {},
    filters: {
      lettre: "Tous",
      thematiques: [],
      radio: {
        national: {
          tid: 9,
          active: true,
        },
        local: {
          tid: null,
          name: "",
          active: false,
        },
      },
      diffusion: true,
    },
    requestArgs: null,
    loading: false,
    prayers: {},
});

export const mutations = {
  setId(state, id) {
    state.id = id;
  },
  setFull(state, show) {
    state.show = show;
  },
  setPager(state, pager) {
    state.pager = pager;
  },
  setEpisodes(state, episodes) {
    state.episodes = episodes;
  },
  addEpisodes(state, episodes) {
    state.episodes = [...state.episodes, ...episodes];
  },
  resetEpisodes(state) {
    state.episodes = [];
  },
  resetPager(state) {
    state.pager = {};
  },
  setGlossary(state, shows) {
    state.shows = shows;
  },
  setEpisode(state, episode) {
    state.episode = episode;
  },
  setNationalPrayers(state, nationalPrayers) {
    state.nationalPrayers = nationalPrayers;
  },
  setLocalPrayers(state, localPrayers) {
    state.localPrayers = localPrayers;
  },
  setPrayers(state, prayers) {
    state.prayers = prayers;
  },
  setFilters(state, filters) {
    state.filters = filters;
  },
  setRequestArgs(state, args) {
    state.requestArgs = args;
  },
  setTodaySaint(state, todaySaint) {
    state.todaySaint = todaySaint;
  },
  setSaintEpisode(state, saintEpisode) {
    state.saintEpisode = saintEpisode;
  },
  setLastSaintsEpisodes(state, lastSaintsEpisodes) {
    state.lastSaintsEpisodes = lastSaintsEpisodes;
  },
  resetShow(state) {
    state.id = 0;
    state.show = [];
    state.episodes = [];
    state.shows = [];
    state.episode = [];
    state.nationalPrayers = [];
    state.localPrayers = [];
    state.pager = {};
    state.filters.lettre = "Tous";
    state.filters.thematiques = [];
    state.filters.radio.national.tid = 9;
    state.filters.radio.national.active = true;
    state.filters.radio.local.tid = null;
    state.filters.radio.local.name = "";
    state.filters.radio.local.active = false;
    state.filters.diffusion = true;
    state.requestArgs = null;
  },
  setLoading(state, value) {
    state.loading = value
  }
};

export const actions = {
  async addEpisodes({ commit, state }) {},

  async getFull({ commit, state }, args) {
    let params = {};
    if (typeof args.page_id !== "undefined") {
      params = {
        args: {
          page: args.page_id,
        },
      };
    }

    // Gets the show or podcastSelection
    if (args.show) {
      commit("setFull", args.show);
      if (typeof args.show.id) {
        if (args.show.id != state.id) {
          // not the same show than before
          commit("resetEpisodes");
          commit("resetPager");
        }
        params = {
          ...params,
          id: args.show.id,
        };
        let episodes = [];
        try{
          if (state.show.type === "podcast_selection") {
            episodes = await this.$podcastSelectionRepository.getByIdWithParam(
              params
            );
          } else if (state.show.type === "show") {
            episodes = await this.$episodesByShowRepository.getByIdWithParam(params);
          }
          commit("setEpisodes", episodes.rows);
          commit("setPager", episodes.pager);
          commit("setId", args.show.id);
        } catch(e) {
          throw new Error('API call to {' + state.show.type + ' failed');
        }
        
      }
    }

    // Gets the pushed episode if asked in routeParams and it's first page
    if (
      (!args.page_id || args.page_id === 0) &&
      (args.episode || this.$router.currentRoute.query.episode)
    ) {
      let episode = await this.$episodeRepository.getByIdWithoutParams(
        args.episode
      );
      commit("setEpisode", episode);
    } else {
      commit("setEpisode", []);
    }
  },

  async getEpisodes({ commit, state }, params) {
    let episodes = [];

    if (!params.id && state.show.id) {
      params = {
        ...params,
        args: {
          ...state.requestArgs,
          page: params.page_id,
        },
        id: state.show.id,
      };
    }
    try {
      if (state.show.type === "podcast_selection") {
        episodes = await this.$podcastSelectionRepository.getByIdWithParam(
          params
        );
      } else if (state.show.type === "show") {
        episodes = await this.$episodesByShowRepository.getByIdWithParam(params);
      }
  
      if (params.type === "get") {
        commit("setEpisodes", episodes.rows);
      } else if (params.type === "add") {
        commit("addEpisodes", episodes.rows);
      }
      commit("setPager", episodes.pager);
  
      // Gets the pushed episode if asked in routeParams and it's first page
      if (
        (!this.$router.currentRoute.query.page ||
          this.$router.currentRoute.query.page === 0) &&
        this.$router.currentRoute.query.episode
      ) {
        let episode = await this.$nodeRepository.getByUrl(
          "media/" + this.$router.currentRoute.query.episode + "/edit"
        );
        commit("setEpisode", episode);
      } else {
        commit("setEpisode", []);
      }
    } catch(e) {
      throw new Error('API call {getEpisodes} failed');
    }
  },

  resetEpisodesData({ commit }) {
    commit("resetEpisodes");
    commit("resetPager");
  },

  resetShow({ commit }) {
    commit("resetShow");
  },

  async getGlossary({ commit, state, rootState }, args) {
    const { filters } = args;
    let url = "emissions-a-z";
    const newFilters = { ...filters, ...state.filters };
    const params = {};

    if (filters.lettre) {
      const lettre =
        filters.lettre === "0-9"
          ? "0,1,2,3,4,5,6,7,8,9,%23,%22,("
          : filters.lettre;
      url = `${url}/${lettre}`;
      newFilters.lettre = lettre;
    }
    if (filters.radioNational) {
      params["radio[0]"] = 9;
      newFilters.radio.national.active = true;
    } else {
      newFilters.radio.national.active = false;
    }
    if (![null, rootState.radio.radioNationalTid].includes(rootState.radio.radioTid)) {
      if (filters.radioLocale) {
        params["radio[1]"] = rootState.radio.radioTid;
        newFilters.radio.local.active = true;
      }
      else {
        newFilters.radio.local.active = false;
      }
      newFilters.radio.local.tid = rootState.radio.radioTid;
      newFilters.radio.local.name = rootState.radio.radioName;
    }
    else {
      newFilters.radio.local.tid = null;
      newFilters.radio.local.name = '';
      newFilters.radio.local.active = false;
    }
    if (filters.diffusion) {
      params["diffusion[]"] = 1;
    }

    newFilters.diffusion = !!filters.diffusion;

    if (filters.thematiques) {
      filters.thematiques.forEach((thematique, index) => {
        params[`thematique[${index}]`] = thematique;
      });
    }

    if (filters.page) params.page = filters.page-1;

    const shows = await this.$defaultRepository.getByUrl(url, params);
    const newRows = [];
    const tempThematics = {};

    shows.categories.forEach((category) => {
      tempThematics[category.id] = category.name;
    });

    let tempRows = {};

    if (shows.rows) tempRows = shows.rows;
    else if (shows[filters.lettre])
      tempRows = { [filters.lettre]: shows[filters.lettre] };

    for (let key in tempRows) {
      newRows.push({
        title: key,
        data: tempRows[key].map((show) => {
          return {
            ...show,
            field_category: tempThematics[show.field_category],
          };
        }),
      });
    }

    const newThematiques = shows.categories.map((category) => {
      return {
        ...category,
        active: filters?.thematiques?.includes(category.id),
      };
    });

    shows.rows = newRows;

    if (!shows.pager) {
      shows.pager = {
        current_page: 0,
        total_items: shows.rows.length ? shows.rows[0].data.length : 0,
        total_pages: 1,
        items_per_page: 20,
      };
    }

    if (filters.page && state.shows.rows) {
      const lastLetter = state.shows.rows[state.shows.rows.length - 1];
      if (lastLetter.title === shows.rows[0].title) {
        // La page précédente se termine avec la même lettre que la page actuelle
        const lastPageLength = state.shows.rows.length - 1;
        const lastPageDataWithoutLastLetter = [
          ...state.shows.rows.slice(0, lastPageLength),
        ];
        const lastPageLastLetter = { ...state.shows.rows[lastPageLength] };
        const actualPageDataWithoutFirstLetter = [...shows.rows.slice(1)];

        lastPageLastLetter.data = [
          ...lastPageLastLetter.data,
          ...shows.rows[0].data,
        ];

        shows.rows = [
          ...lastPageDataWithoutLastLetter,
          lastPageLastLetter,
          ...actualPageDataWithoutFirstLetter,
        ];
      } else {
        shows.rows = [...state.shows.rows, ...shows.rows];
      }
    }

    commit("setFilters", {
      ...newFilters,
      thematiques: newThematiques,
    });
    commit("setGlossary", shows);
  },

  async getPrayersShows({ commit, rootState }, url) {
    let radio = (rootState.radio.radioTid == null) ? rootState.radio.radioNationalTid ?? 9 : rootState.radio.radioTid;
    try {
      let prayers = await this.$defaultRepository.getByUrl(url + "/" + radio);
      commit("setPrayers", prayers);
    } catch(e) {
      throw new Error('API call to {prayers} failed');
    }


  },
  async searchShow({ }, searchText) {
    let radiosTid = [];
    if (this.$cookies.get("cookie") != undefined) {
      radiosTid.push(this.$cookies.get("cookie").radioNationalTid);
      if (
        this.$cookies.get("cookie").radioTid !=
        this.$cookies.get("cookie").radioNationalTid
      )
        radiosTid.push(this.$cookies.get("cookie").radioTid);
    } else {
      radiosTid.push(9);
    }
    let results = await this.$defaultRepository.searchShows(
      searchText,
      radiosTid
    );
    return results;
  },

  setRequestArgs({ commit }, args) {
    commit("setRequestArgs", args);
  },
  async getTodaySaint({ commit, dispatch }, args) {
    try {
      const todaySaint = await this.$saintRepository.get();
      if (todaySaint) {
        dispatch("getLastSaintsEpisodes", todaySaint.saint_du_jour.id);
        commit("setTodaySaint", todaySaint);
      }
      if (todaySaint?.saint_du_jour?.episode)
        commit("setSaintEpisode", todaySaint.saint_du_jour.episode);

      // Gets the pushed episode if asked in routeParams and it's first page
      if (
        (!args.page_id || args.page_id === 0) &&
        (args.episode || this.$router.currentRoute.query.episode)
      ) {
        let episode = await this.$episodeRepository.getByIdWithoutParams(
          args.episode
        );
        commit("setEpisode", episode);
      } else {
        commit("setEpisode", [])
      }
    } catch (e) {
      console.log(e);
    }
  },
  async getLastSaintsEpisodes({ commit }, id) {
    const lastSaintsEpisodes = await this.$episodesByShowRepository.getByIdWithParam({
      id,
    });

    commit("setLastSaintsEpisodes", lastSaintsEpisodes);
  },
  async getNextSaintsEpisodes({ commit, state }, { id, pager, pageId}) {
    const lastSaintsEpisodes = state.lastSaintsEpisodes.rows
    const nextSaintsEpisodes = await this.$episodesByShowRepository.getByIdWithParam({
      id,
      args: { page: pageId},
    });

    const combinedEpisodes = [ ...lastSaintsEpisodes, ...nextSaintsEpisodes.rows]

    commit("setLastSaintsEpisodes", {
      ...nextSaintsEpisodes,
      rows: combinedEpisodes
    });
  },
  async getSaintSearch({ commit }, saintSearchParams) {
    const { name, date } = saintSearchParams;
    let url = "api/saintdujour/search";

    if (name) url = `${url}/${name}`;
    else if (date) url = `${url}?day=${date.day}&month=${date.month}`;

    try {
      const saint = await this.$saintRepository.getByUrl(url);

      if (saint)
        commit("setSaintEpisode", Array.isArray(saint) ? saint[0] : saint);
        commit("setEpisode", {});
    } catch (e) {
      console.log(e);
    }
  },
  updateFilters({ commit, state }, filters) {
    commit("setFilters", { ...state.filters, ...filters });
  },

  loading({commit}) {
      commit('setLoading', true)
  },
  loaded({commit}) {
      commit('setLoading', false)
  },
};
