import { getUrlWithFormat } from "~/utils/formatImgUrl";

// Fonction utilitaire pour supprimer les balises HTML d'une chaîne de caractères
function removeHtmlTags(input) {
  if (!input) {
    return null
  }
  return input
    .replace(/<\/?[^>]+(>|$)/g, '') // Supprimer les balises HTML
    .replace(/&amp;/g, '&') // Remplacer "&amp;" par "&"
    .replace(/&nbsp;/g, ' ') // Remplacer "&nbsp;" par un espace
    .replace(/\s+/g, ' ') // Remplacer plusieurs espaces par un seul espace
}

// Fonction pour détecter le type d'entité en fonction de ses propriétés
function detectEntityType(entity) {
  if (entity?.locale?.header?.type === 'category' || entity?.natio?.header?.type === 'category') {
    return 'category';
  } else if (entity?.aroundMePage) {
    return 'aroundMePage';
  } else if (entity?.basic) {
    return 'basic';
  } else if (entity?.type && entity.type === 'folder') {
    return 'folder';
  } else if (entity?.type && entity.type === 'article') {
    return 'article';
  } else if (entity?.type && entity.type === 'show') {
    return 'show';
  } else if (entity?.type && entity.type === 'episode') {
    return 'episode';
  } else if (entity?.type && entity.type === 'podcast_selection') {
    return 'podcastSelection';
  } else if (entity?.type && entity.type === 'partner') {
    return 'partner';
  } else if (entity?.type && entity.type === 'tag') {
    return 'tag';
  } else if (entity?.todaySaint && entity.todaySaint?.saint_du_jour?.type === 'show') {
    return 'sdj';
  } else {
    return 'default';
  }
}

// Dictionnaire de configurations pour chaque type d'entité
const entityConfigurations = {
  category: buildCategoryHead,
  aroundMePage: buildAroundMePageHead,
  basic: buildBasicHead,
  folder: buildFolderHead,
  article: buildArticleHead,
  show: buildShowHead,
  episode: buildEpisodeHead,
  podcastSelection: buildPodcastSelectionHead,
  partner: buildPartnerHead,
  tag: buildTagHead,
  sdj: buildSdjHead,
  default: buildDefaultHead
};

// ------- Définir les fonctions de construction pour chaque type d'entité ------- //

// Fonction de construction par défaut pour les types d'entités non pris en charge
function buildDefaultHead(entity, pager) {
  const metatag = entity?.metatag?.value || {}
  return { metatag, pager } ;
}

// Construction des metatags pour la catégorie
function buildCategoryHead(entity, pager, $config, $route) {
  let description = entity.locale.header.description ? entity.locale.header.description : entity.natio.header.description
  description = removeHtmlTags(description)
  let articleUne
  let image
  if (entity.locale.items && entity.locale.items.length > 0) {
    articleUne = entity.locale.items[0]
    image = getUrlWithFormat(articleUne.visual.url, '1280x630_og_image')
  } else if (entity.natio.items && entity.natio.items.length > 0){
    articleUne = entity.natio.items[0]
    image = getUrlWithFormat(articleUne.visual.url, '1280x630_og_image')
  }
  // TODO : Utiliser les données Radio ajoutées à la ressource back pour set entity.locale.header.radio dans le store et l'utiliser ici
  const title = entity.locale.header.name ? entity.locale.header.name /**+ ' RCF ' + entity.radio**/ : entity.natio.header.name
  const metatag = {
    canonical_url: $config.baseURL + $route.fullPath,
    description: description ?? '',
    og_image_0: image,
    title: title,
  };

  return { metatag, pager }
}

// Construction des metatags pour la page "Around Me"
function buildAroundMePageHead(entity, pager, $config, $route) {
  let description = entity.aroundMePage?.description ? removeHtmlTags(entity.aroundMePage.description) : ''
  const metatag = {
    canonical_url: $config.baseURL + $route.fullPath,
    description: description ?? '',
    title: entity.aroundMePage.title
  }
  return { metatag, pager }
}

// Construction des metatags pour les pages de base
function buildBasicHead(entity, pager) {
  let description = entity.basic.field_page_chapo && entity.basic.field_page_chapo ? 
                    removeHtmlTags(entity.basic.field_page_chapo[0].value) : (entity.basic.description ? entity.basic.description : '')
  const metatag = {
    description: description ?? '',
    title: typeof(entity.basic.title) == 'array' ? entity.basic.title[0].value : entity.basic.title,
  }
  return { metatag, pager }
}

// Construction des metatags pour le type d'entité "folder"
function buildFolderHead(entity, pager, $config, $route) {
  let image = entity.folder_visual.url.replace('154x154', '{style_name}');
  let description = entity.description ? removeHtmlTags(entity.description) : null;
  let metaDescription = entity.meta_description ? removeHtmlTags(entity.meta_description) : null
  let ogDescription = entity.og_description ? removeHtmlTags(entity.og_description) : null

  const metatag = {
    canonical_url: $config.baseURL + $route.fullPath,
    description: description ?? '',
    title: entity.title + ' | RCF',
    meta_description: metaDescription != null ? metaDescription : (description ? description : ''),
    og_description: ogDescription != null ? ogDescription : (description ? description : ''),
    og_image_0: getUrlWithFormat(image, '1280x630_og_image'),
    og_site_name: 'RCF',
    og_title: entity.og_title && entity.og_title ? entity.og_title + ' | RCF'
          : entity.title + ' | RCF',
    schema_article_author_0: {
      "@type": "NGO",
      "name": "RCF"
    },
    schema_article_date_modified: entity.change_time,
    schema_article_date_published: entity.published_time,
    schema_article_headline: description,
    schema_article_image: {
      "@type": "ImageObject",
      "representativeOfPage": "True",
      "url": getUrlWithFormat(image, '930x557')
    },
    schema_article_is_accessible_for_free: 'True',
    schema_article_main_entity_of_page: $config.baseURL + $route.fullPath,
    schema_article_name: entity.title + ' | RCF',
    schema_article_publisher: {
      "@type": "NGO",
      "name": "RCF"
    },
    schema_article_type: 'BackgroundNewsArticle',
  };

  return { metatag, pager }
}

// Construction des metatags pour le type d'entité "article"
function buildArticleHead(entity, pager, $config, $route) {
  let description = removeHtmlTags(entity.description)
  let metaDescription = removeHtmlTags(entity.meta_description)
  let ogDescription = removeHtmlTags(entity.og_description)
  const metatag = {
    canonical_url: $config.baseURL + $route.fullPath,
    description: description ?? '',
    title: entity.meta_title + ' | ' + entity.radios[0].name,
    meta_description: metaDescription,
    og_description: ogDescription,
    og_image_0: getUrlWithFormat(entity.visual.url, '1280x630_og_image'),
    og_site_name: 'RCF',
    og_title: entity.og_title + ' | ' + entity.radios[0].name,
    schema_article_author_0: {
      "@type": "Person",
      "name": entity.author ? entity.author : ''
    },
    schema_article_date_modified: entity.full_changed_time,
    schema_article_date_published: entity.full_published_time,
    schema_article_headline: entity.title + ' | RCF',
    schema_article_image: {
      "@type": "ImageObject",
      "url": getUrlWithFormat(entity.visual.url, '930x557')
    },
    schema_article_is_accessible_for_free: 'True',
    schema_article_main_entity_of_page: $config.baseURL + $route.fullPath,
    schema_article_name: entity.title,
    schema_article_publisher: {
      "@type": "NGO",
      "name": "RCF"
    },
    schema_article_type: 'NewsArticle',
  };
  return { metatag, pager }
}

function buildShowHead(entity, pager, $config, $route) {
  let hasPart = [];
  let description = removeHtmlTags(entity.description)
  let path = $route.path
  
  // for (let episode of entity.episodes) {
  //   let item = {
  //     "@type": "RadioEpisode",
  //     "name": episode.title,
  //     "url": $config.baseURL + path + '?episode=' + episode.id,
  //   }
  //   hasPart.push(item)
  // }

  const metatag = {
    canonical_url: $config.baseURL + path,
    description: description ?? '',
    title: entity.title + ' | ' + entity.radios[0].name,
    og_description: description ?? '',
    og_image_0: getUrlWithFormat(entity.visual.url, '1280x630_og_image'),
    og_site_name: 'RCF',
    og_title: entity.title + ' | ' + entity.radios[0].name,
    schema_movie_description: description,
    // schema_movie_has_part_0: hasPart,
    schema_movie_image: {
      "@type": "ImageObject",
      "url": getUrlWithFormat(entity.visual.url, '930x557'),
    },
    schema_movie_name: entity.title + ' | RCF',
    // schema_movie_type: "RadioSeries",
    schema_movie_url_0: $config.baseURL + path,
  };
  return { metatag, pager }
}

function buildEpisodeHead(entity, pager, $config, $route) {
  const metatag = {
    canonical_url: $config.baseURL + $route.path,
    title: entity.title + ' | ' + entity.show.title + ' | ' + entity.radios[0].name,
    og_image_url_0: getUrlWithFormat(entity.visual.url, '1280x630_og_image'),
    og_site_name: 'RCF',
    og_title: entity.title + ' | ' + entity.show.title,
    schema_movie_date_created: entity.datetime,
    schema_movie_duration: entity.duration,
    schema_movie_id: entity.id,
    schema_movie_name: entity.title + ' | ' + entity.show.title,
    schema_movie_part_of_series: {
      "@type": "RadioSeries",
      "name": entity.show.title,
      "url": $config.baseURL + entity.show.alias,
    },
    schema_movie_production_company_0: {
      "@type": "RadioStation",
      "name": "RCF",
    },
    schema_movie_type: "RadioEpisode",
    schema_movie_url_0: $config.baseURL + $route.fullPath,
  };
  return { metatag, pager }
}

function buildPodcastSelectionHead(entity, pager, $config, $route) {
  let description = removeHtmlTags(entity.description)
  const metatag = {
    canonical_url: $config.baseURL + $route.fullPath,
    title: entity.title + ' | RCF',
    description: description ?? '',
    og_description: description ?? '',
    og_image_0: getUrlWithFormat(entity.visual.url, '1280x630_og_image'),
    og_site_name: 'RCF',
    og_title: entity.title + ' | RCF',
    schema_movie_description: description,
    schema_movie_image: {
      "@type": "ImageObject",
      "url": getUrlWithFormat(entity.visual.url, '930x557')
    },
    schema_movie_type: "RadioSeries",
    schema_movie_url_0: $config.baseURL + $route.fullPath,
  };
  return { metatag, pager }
}

function buildPartnerHead(entity, pager, $config, $route) {
  const metatag = {
    canonical_url: $config.baseURL + $route.fullPath,
    title: entity.title + ' | RCF',
  };
  return { metatag, pager }
}


function buildTagHead(entity, pager, $config, $route) {
  const metatag = {
    canonical_url: $config.baseURL + $route.fullPath,
    title: entity.name + ' | RCF',
  };
  return { metatag, pager }
}

function buildSdjHead(entity, pager, $config, $route) {
  let hasPart = [];
  let description = removeHtmlTags(entity.todaySaint.saint_du_jour.description)
  let path = $route.path
  if (entity.lastSaintsEpisodes) {
    for (let episode of entity.lastSaintsEpisodes) {
      let item = {
        "@type": "RadioEpisode",
        "name": episode.title,
        "url": $config.baseURL + path + '?episode=' + episode.id,
      }
      hasPart.push(item)
    }
  }

  const metatag = {
    canonical_url: $config.baseURL,
    description: description ?? '',
    title: entity.todaySaint.saint_du_jour.title + ' | RCF',
    og_description: description ?? '',
    og_image_0: getUrlWithFormat(entity.todaySaint.saint_du_jour.visual.url, '1280x630_og_image'),
    og_site_name: 'RCF',
    og_title: entity.todaySaint.saint_du_jour.episode ? entity.todaySaint.saint_du_jour.episode.title + ' | RCF' : 'RCF',
    schema_movie_description: description,
    schema_movie_has_part_0: hasPart,
    schema_movie_image: {
      "@type": "ImageObject",
      "url": getUrlWithFormat(entity.todaySaint.saint_du_jour.visual.url, '930x557'),
    },
    schema_movie_name: entity.todaySaint.saint_du_jour.title + ' | RCF',
    schema_movie_type: "RadioSeries",
    schema_movie_url_0: $config.baseURL + path,
  };
  return { metatag, pager }
}

// API Repository
export default ($config, $route) => resource => ({

  buildHead(entity, pager) {
    if (!pager) {
      pager = {}
    }
    const entityType = detectEntityType(entity);
    const buildFunction = entityConfigurations[entityType] || buildDefaultHead;
    const result = buildFunction(entity, pager, $config, $route);
    let meta = this.buildMeta(result?.metatag, result?.pager)
    let head = { ...meta };

    let jsonld = this.buildJsonLd(result?.metatag)
    if(jsonld !== null) {
      head = {
        ...head,
        ...jsonld
      }
    }

    if (entityType === 'default') {
      const pageTitle = entity?.metatag?.value?.title
      switch (pageTitle) {
        case 'Les dossiers | RCF':
        case 'Programmes | RCF':
        case 'Les émissions Prière | RCF':
        case 'RCF, radio chrétienne | Le top des podcasts':
          let linkPage = this.buildInitialLink()
          head = {
            ...head,
            ...linkPage
          }
          break;
      }
    }
    if (pager && pager.current_page > 0) {
      let selfCanonicalLink = this.buildSelfCanonical(pager)
      head = {
        ...head,
        ...selfCanonicalLink
      }
    }

    if (entity?.type && entity.type === "show") {
      let linkShow = this.buildLinkWithRSS('emission', entity.id, entity.title, entity.alias, pager.current_page)
      head = {
        ...head,
        ...linkShow
      }
    } else if (entity?.todaySaint && entityType === 'sdj') {

      let linkSdj
      linkSdj = this.buildLinkWithRSS('podcast', entity.todaySaint.saint_du_jour.id, entity.todaySaint.saint_du_jour.title, entity.todaySaint.saint_du_jour.alias, pager.current_page)
      head = {
        ...head,
        ...linkSdj
      }
    } else if (entity?.audio_file) {

      let linkEpisode
      let episodePath = entity.show.alias + '?episode=' + entity.id

      linkEpisode = this.buildEpisodeLink(episodePath)
      head = {
        ...head,
        ...linkEpisode
      }
    } else if (entityType === 'category' || entityType === 'default') {

      let linkPage
      if (pager && pager.current_page === 0) {
        linkPage = this.buildInitialLink()
        head = {
          ...head,
          ...linkPage
        }
      }
    } else if (entityType === 'article' || entityType === 'folder' || entityType === 'basic') {
          
      let linkPage = this.buildInitialLink()
      head = {
        ...head,
        ...linkPage
      }
    }
    
    return head
  },

  buildSelfCanonical(pager) {
    let param = ""
    if (pager.current_page > 0) {
      param = '?page='+ (pager.current_page + 1)
    }
    if (pager.chosenLetter) {
      param = '?lettre='+pager.chosenLetter
    }
    return {
      link: [
        {
          rel: 'canonical',
          href: $config.baseURL + $route.path + param
        },
      ],
    }
  },

  buildLinkWithRSS(type, nid, title, alias, pageNb){
    let param = ""
    if (pageNb && pageNb > 0) {
      param = '?page='+ (pageNb + 1)
    }
    
    return {
      link: [
        {
          rel: 'canonical',
          href: $config.baseURL + alias + param
        },
        {
          rel: 'alternate',
          type: 'application/rss+xml',
          href: $config.baseURL + '/feed/show/' + nid,
          title: title
        }
      ],
    }
  },

  buildInitialLink() {
    return {
      link: [
        {
          rel: 'canonical',
          href: $config.baseURL + $route.fullPath
        }
      ]
    }
  },

  buildEpisodeLink(alias) {
    return {
      link: [
        {
          rel: 'canonical',
          href: $config.baseURL + alias
        }
      ]
    }
  },

  buildMeta(meta, pager) {
    let pageNb
    let lettre
    let seoSubtitle
    pager.current_page > 0 ? pageNb = " - page " + (pager.current_page + 1) : pageNb = ""
    pager.chosenLetter ? lettre = " - lettre " + pager.chosenLetter : lettre = ""

    pageNb !== "" ? seoSubtitle = pageNb : seoSubtitle = lettre

    return {
      title: (meta.meta_title ? meta.meta_title : meta.title) + seoSubtitle,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: meta.meta_description ? meta.meta_description : meta.description
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: meta.og_image_0 ? meta.og_image_0 : (meta.og_image_url_0 ? meta.og_image_url_0 : '')
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: meta.og_title ? meta.og_title : meta.title
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: meta.og_description ? meta.og_description : meta.description
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: $config.baseURL + $route.fullPath
        },
        {
          hid: 'twitter:card',
          name: 'twitter:card',
          content: 'summary_large_image'
        },
        {
          hid: 'twitter:site',
          name: 'twitter:site',
          content: '@radiorcf'
        },
        {
          hid: 'twitter:title',
          name: 'twitter:title',
          content: meta.og_title ? meta.og_title : meta.title
        },
        {
          hid: 'twitter:description',
          name: 'twitter:description',
          content: meta.og_description ? meta.og_description : (meta.description ? meta.description : ''),
        },
        {
          hid: 'twitter:image',
          name: 'twitter:image',
          content: meta.og_image_0 ? meta.og_image_0 : meta.og_image_url_0 ? meta.og_image_url_0 : ''
        }
      ],
    }
  },

  buildJsonLd (jsonld) {
    let buildedJsonLd = null
    let objectJsonLd = {}
    for (let jsonldItem in jsonld){
      if(jsonldItem.includes("schema_")){
        switch (true) {
          case (jsonldItem.includes("_type") === true):
            objectJsonLd = {
              ...objectJsonLd,
              '@type':jsonld[jsonldItem]
            }
            break;
          case (jsonldItem.includes("_headline") === true):
            objectJsonLd = {
              ...objectJsonLd,
              'headline':jsonld[jsonldItem]
            }
            break;
          case (jsonldItem.includes("_name") === true):
            objectJsonLd = {
              ...objectJsonLd,
              'name':jsonld[jsonldItem]
            }
            break;
          case (jsonldItem.includes("_url") === true):
            objectJsonLd = {
              ...objectJsonLd,
              'url':jsonld[jsonldItem].replace($config.backURL, $config.baseURL)
            }
            break;
          case (jsonldItem.includes("_description") === true):
            objectJsonLd = {
              ...objectJsonLd,
              'description':jsonld[jsonldItem]
            }
            break;
          case (jsonldItem.includes("_image") === true):
            objectJsonLd = {
              ...objectJsonLd,
              'image':jsonld[jsonldItem]
            }
            break;
          case (jsonldItem.includes("_date_published") === true):
            objectJsonLd = {
              ...objectJsonLd,
              'datePublished':jsonld[jsonldItem]
            }
            break;
          case (jsonldItem.includes("_date_modified") === true):
            objectJsonLd = {
              ...objectJsonLd,
              'dateModified':jsonld[jsonldItem]
            }
            break;
          case (jsonldItem.includes("_is_accessible_for_free") === true):
            objectJsonLd = {
              ...objectJsonLd,
              'isAccessibleForFree':jsonld[jsonldItem]
            }
            break;
          case (jsonldItem.includes("_author") === true):
            objectJsonLd = {
              ...objectJsonLd,
              'author':jsonld[jsonldItem]
            }
            break;
          case (jsonldItem.includes("_publisher") === true):
            objectJsonLd = {
              ...objectJsonLd,
              'publisher':jsonld[jsonldItem]
            }
            break;
          case (jsonldItem.includes("_main_entity_of_page") === true):
            objectJsonLd = {
              ...objectJsonLd,
              'mainEntityOfPage':jsonld[jsonldItem].replace('http', 'https').replace($config.backURL, $config.baseURL)
            }
            break;
          case (jsonldItem.includes("_has_part") === true):
            objectJsonLd = {
              ...objectJsonLd,
              'hasPart':jsonld[jsonldItem]
            }
            break;
          case (jsonldItem.includes("_part_of_series") === true):
            objectJsonLd = {
              ...objectJsonLd,
              'partOfSeries':jsonld[jsonldItem]
            }
            break;
          default:
            break;
        }
      }
    }

    if(Object.entries(objectJsonLd).length > 0) {
      buildedJsonLd = {
        __dangerouslyDisableSanitizers: ['script'],
        script: [
          {
            innerHTML: JSON.stringify({
              "@context": "https://schema.org",
              "@graph": [
                objectJsonLd
              ]
            }),
            type: 'application/ld+json'
          }
        ]
      }
    }
    return buildedJsonLd
  }
})
