export default (store, $config, cookies, ctx) => ressource => ({
  getGlobalObject(pageType, gtmParams, show = null) {
    const userId = ctx.$auth.isAuthenticated ? true : false;
    const tags = (pageType === 'Article' || pageType === 'Dossier') ? this.getTags(pageType, gtmParams?.tags) : [];
    
    const radioCodeList = gtmParams?.radios || [];
    const partnerList = gtmParams?.partners || [];
  
    let dataLayerObject = {
      'event': 'dataLayerReady',
      'environnement': $config.appEnv,
      'userId': userId,
      'radioCode': radioCodeList.join(","),
      'radio': this.getSelectedRadio(),
      'partenaire': partnerList.join(","),
      'pageType': pageType,
      'tagArticle': tags.join(",")
    };
  
    if (show) {
      dataLayerObject = {
        ...dataLayerObject,
        'event': 'emission-pv',
        'emission-pv': show
      };
    }
  
    return dataLayerObject;
  },

  // Create a podcastStart event
  getPodcastStartObject(pageType) {
    return {
      'event': 'podcastStart',
      'eventCategory': 'podcast',
      'eventAction': 'Ecoute podcast',
      'eventLabel': this.getShowTitle(),
      'episode': this.getEpisodeTitle(),
      'radio': this.getSelectedRadio(),
      'radioCode': this.getPodcastRadios(),
      'diffusionType': this.getDiffusionType()
    }    
  },

  // Create a podcastActive event
  getPodcastActiveObject() {
    return {
      'event': 'podcastActive',
      'eventCategory': 'podcast',
      'eventAction': 'Ecoute active',
      'eventLabel': this.getShowTitle(),
      'episode': this.getEpisodeTitle(),
      'radio': this.getSelectedRadio(),
      'radioCode': this.getPodcastRadios(),
      'diffusionType': this.getDiffusionType()
    }    
  },

  // Create a podcastOngoing event
  getPodcastOngoingObject(threshold) {
    return {
      'event': 'podcastOngoing',
      'eventCategory': 'podcast',
      'eventAction': threshold,
      'eventLabel': this.getShowTitle(),
      'episode': this.getEpisodeTitle(),
      'radio': this.getSelectedRadio(),
      'radioCode': this.getPodcastRadios(),
      'diffusionType': this.getDiffusionType()
    }    
  },

  // Tool box
  htmlDecode(text){
    var txt = document.createElement('textarea');
    txt.innerHTML = text;
    return txt.value;
  },
  getTags(pageType, tags=null) {
    if (pageType === 'Article') return tags
    let folderState = store.state.folder.folder;
    let folderTags = folderState ? folderState.field_folder_tag : null;
    if (pageType === 'Dossier' && !folderState) return [];
    return folderTags ? folderTags.map(tag => tag.target_label) : [];
  },
  push(object) {
    ctx.$gtm.push(object);
  },
  getPodcastRadios() {
    return (store.state.audioPlayer.isLiveStream === true) ? 
      null : 
      store.state.audioPlayer.soundData.radios? store.state.audioPlayer.soundData.radios.map(radio => radio.code).join(',') : null;
  },
  getSelectedRadio() {
    if(cookies.get('cookie')) {
      return this.htmlDecode(cookies.get('cookie').radioName)
    } else {
      return 'National'
    }
  },
  getEpisodeTitle() {
    if(store.state.audioPlayer.soundData.title){
      return this.htmlDecode(store.state.audioPlayer.soundData.title)
    } else {
      return 'sans_titre'
    }
  },
  getDiffusionType() {
    return store.state.audioPlayer.isLiveStream === true ? 'Live' : 'Podcast'
  },
  getShowTitle() {
    if (store.state.audioPlayer.isLiveStream === true) {
      return cookies.get('cookie') ? this.htmlDecode(cookies.get('cookie').radioName) : 'National'
    } else {
      return store.state.audioPlayer.soundData.show ? this.htmlDecode(store.state.audioPlayer.soundData.show.title) : 'sans_emission';
    }
  }
})
