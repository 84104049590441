export const namespaced = true;
export const state = () => ({
  trackerId: null,
  userId: null,
  mediaId: null,
  trackerInterval: null,
  offset: 0,
  status: 1 // 0 -> deja écouté | 1 -> non terminé
})

export const mutations = {
  setId(state, id) {
    state.trackerId = id
  },
  setUserId(state, userId) {
    state.userId = userId
  },
  setMediaId(state, mediaId) {
    state.mediaId = mediaId
  },
  setOffset(state, offset) {
    state.offset = offset
  },
  setStatus(state, status) {
    state.status = status
  },
  setTrackerInterval(state, interval) {
    state.trackerInterval = interval
  }
}

export const actions = {
  /**
   * Crée ou met à jour le tracker
   * @Params rootState : tout le store
   * */
  async updateListeningTracker({ commit, rootState, state }) {
    let data
    let offsetInMs = Math.trunc(rootState.audioPlayer.mainSoundInstance.currentTime * 1000) // seconds to milliseconds
    
    commit('setOffset', offsetInMs)

    if (offsetInMs > 0) {

      data = {
        "field_replay_offset": [{"value": state.offset}]
      }
    }
    else {
      commit('setStatus', 0)
      data = {
        "field_replay_offset": [{"value": state.offset}],
        "field_replay_status": [{ "value": state.status }]
      }
    }

    try {
      if (state.trackerId !== null) { // si tracker existe (=> podcast déjà écouté par l'utilisateur
        let update = this.$trackingReplay.patchTrackingReplay(data, state.trackerId.toString())
      } else {
        data = {
          "field_replay_wso2_uid": [{"value": state.userId}],
          "field_replay_media_id": [{"target_id": state.mediaId}],
          "field_replay_offset": [{"value": state.offset}],
          "field_replay_status": [{ "value": state.status }]
        }
        let createdTracker = await this.$postTrackingReplay.postTrackingReplay(data) // on attend que les données arrivent
        // On met à jour le store avec le tracker créé
        commit('setId', createdTracker.id[0].value)
      }

    } catch (e) {
      
    }
    return 0
  },

  async resetListeningTrackerData({ state, commit }) {
    try {
      if (state.trackerId !== null) {
        let data = {
          "field_replay_offset": [{"value": 0}]
        }
        let update = this.$trackingReplay.patchTrackingReplay(data, state.trackerId.toString())
      } else {
      }
    } catch(e) {
    }
  },

  /**
   * Récupère l'id du tracker concerné
   * @Params userId
   * @Params mediaId : id de l'épisode en cours de lecture
   * @Comment Cette fonction appelle uniquement les trackers /253/153 (cf. TODOS)
   * */
  async getTracker({ commit, state, rootState }) {
    commit('setUserId',rootState.audioPlayer.soundData.id)
    try {
      let tracker = await this.$trackingReplay.getByUrl('rcf_tracking_replay/' + this.$auth.sub + '/' + rootState.audioPlayer.soundData.id)
      commit('setStatus', this.$auth.sub)
      let trackId
      tracker[0] !== undefined ? trackId = tracker[0].id : trackId = null
  
      // On met à jour le store avec le tracker récupéré
      if (trackId != null && trackId !== state.trackerId) {
        commit('setId', trackId)
        commit('setUserId', tracker[0].field_replay_wso2_uid)
        commit('setMediaId', tracker[0].field_replay_media_id)
        commit('setOffset', tracker[0].field_replay_offset)
        commit('setStatus', tracker[0].field_replay_status)
      }
      else if (trackId != null && trackId === state.trackerId) {
        commit('setUserId', tracker[0].field_replay_wso2_uid)
        commit('setMediaId', tracker[0].field_replay_media_id)
        commit('setOffset', tracker[0].field_replay_offset)
        commit('setStatus', tracker[0].field_replay_status)
      } else { // si tracker inexistant
        commit('setId', trackId)
        commit('setMediaId', null)
        commit('setOffset', 0)
      }

    } catch (e) {
      
    }
    return state
  },

  async deleteUserTrackers({}){
    try {
      if(this.$auth.sub != null) {
        await this.$defaultRepository.deleteEntity('tracker', this.$auth.sub)
      }
    } catch(e) {}
  },
}
