import he from 'he';

export const state = () => ({
  radioTid: null,
  radioName: null,
  radioLiveURL: null,
  radioCode: null,
  radio: [],
  radiosByRegions: null,
  radiosByRegion: null,
  radios: [],
  selectedRegion: null,
  radioNationalTid: null,
  radioSocialMedia: null,
});

export const mutations = {
  setRadioSocialMedia(state, value) {
    state.radioSocialMedia = value;
  },
  set(state, radio) {
    state.radioTid = radio.tid;
    state.radioName = radio.name;
    state.radioLiveURL = radio.liveURL;
    state.radioCode = radio.code;

    if (this.$cookies.get("cookie")) {
      state.radioNationalTid = this.$cookies.get("cookie").radioNationalTid;
    } else if (radio.code === this.$config.nationalCode) {
      state.radioNationalTid = radio.tid;
    }

    this.$cookies.set(
      "cookie",
      {
        radioTid: radio.tid,
        radioName: radio.name,
        radioLiveURL: radio.liveURL,
        radioCode: radio.code,
        radioNationalTid: state.radioNationalTid,
        parentRegionTid: radio.parentRegionTid || null,
        parentRegionName: radio.parentRegionName || null,
      },
      {
        path: "/",
        maxAge: 60 * 60 * 24 * 7,
      }
    );
  },
  setRadio(state, radio) {
    state.radio = radio;
  },
  setRadios(state, radios) {
    state.radios = radios;
  },
  setradiosByRegions(state, radiosByRegions) {
    state.radiosByRegions = radiosByRegions;
  },
  setradioByRegion(state, radiosByRegion) {
    state.radiosByRegion = radiosByRegion;
  },
  setSelectedRegion(state, region) {
    state.selectedRegion = region;
  },
  setRadioNationalTid(state, tid) {
    state.radioNationalTid = tid;
  },
};

export const actions = {
  async getRadioNational({ commit, dispatch }, radioCode) {
    try {
      await dispatch("getRadioByCode", radioCode);
      commit("setRadioNationalTid", state.radioTid);
    } catch(e) {
      throw new Error('API call to {getRadioNational} failed');
    }
  },
  async getRadioByCode({ commit, dispatch }, radioData) {
    try {
      let radioByCode = await this.$defaultRepository.getByUrl(
        "radio/" + radioData.radioCode
      );
  
      if (radioByCode.length > 0) {
        let radio = {
          tid: radioByCode[0].id,
          name: he.decode(radioByCode[0].shortname),
          liveURL: radioByCode[0].liveURL,
          code: radioByCode[0].code,
          alias: radioByCode[0].alias,
          parentRegionTid: radioData.parentRegionTid || null,
          parentRegionName: radioData.parentRegionName || null,
        };
        commit("set", radio);

        this.dispatch('radio/getSocialMediaByRadio', null, {root: true})
  
        if (radioByCode[0].alias) {
          dispatch("aroundme/setAlias", radioByCode[0].alias, {root:true});
        }
      }
    } catch(e) {
      throw new Error('API call to {getRadioByCode} failed');
    }
  },
  async setRadioSocialNetworks({ commit }, tid) {
    let $this = this;
    let socialNetworks = {};

    if (typeof tid !== "undefined" && tid !== null) {
      let radio = await this.$taxonomyTermRepository.getByIdWithParam({
        id: tid,
      });

      if (
        typeof radio.field_radio_social_networks !== "undefined" &&
        radio.field_radio_social_networks.length > 0
      ) {
        radio.field_radio_social_networks.forEach(function (item) {
          let snName = item.target_social_network_name;
          socialNetworks = {
            ...socialNetworks,
            [snName]: item.target_social_network_link,
          };
        });

        $this.$cookies.set("radio", socialNetworks, {
          path: "/",
          maxAge: 60 * 60 * 24 * 7,
        });
      } else {
        this.$cookies.remove("radio");
      }
    }
  },
  async getRadio({ commit }, id) {
    try {
      let radio = await this.$taxonomyTermRepository.getByIdWithParam({ id: id });
      commit("setRadio", radio);
    } catch(e) {
      throw new Error('API call to {getRadio} failed');
    }
  },
  async getRadios({ commit }) {
    try {
      let tabRadios = await this.$taxonomyRepository.getByUrl("regions");
      tabRadios.sort((a,b) => a.name.localeCompare(b.name))
      let radios = {}
      tabRadios.forEach(radio => {
        radios[radio.field_radio_code] = he.decode(radio.name)
      })
      
      commit("setRadios", radios)
    } catch(e) {
      throw new Error('API call to {getRadios} failed');
    }
  },
  async getRadiosByRegions({ commit }) {
    try {
      let radiosByRegions = await this.$taxonomyRepository.getByUrl("regions2");
      commit("setradiosByRegions", radiosByRegions);
    } catch(e) {
      throw new Error('API call to {getRadiosByRegions} failed');
    }
  },
  async getRadiosByRegion({ commit }, params) {
    try {
      if (params?.tid) {
        const radiosByRegion = await this.$taxonomyRepository.getByUrl(
          "radios/" + params.tid
        );
        commit("setSelectedRegion", params.selectedRegion);
        commit("setradioByRegion", radiosByRegion);
      } else {
        commit("setSelectedRegion", null);
        commit("setradioByRegion", []);
      }
    } catch(e) {
      throw new Error('API call to {getRadiosByRegions} failed');
    }
  },
  async getSocialMediaByRadio({ commit }) {
    try {
      let radio = 9;
      if (typeof this.$cookies.get("cookie") !== "undefined") {
        radio = this.$cookies.get("cookie").radioTid;
      }
      let socialMedias = await this.$defaultRepository.getByUrl(
        "rest/social_network/" + radio
      );
      commit("setRadioSocialMedia", socialMedias);
    } catch(e) {
      commit("setRadioSocialMedia", null);
    }
  },
};
