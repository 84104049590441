
import { mapState } from "vuex";
import { getUrlWithFormat } from "../../utils/formatImgUrl";

export default {
  name: "AudioPlayer",
  data() {
    return {
      radio: "National",
    };
  },
  components: {
    Link: () => import("./components/Link.vue"),
    Flags: () => import("@/components/flags/Flags"),
    AudioPlayerControls: () => import("./components/AudioPlayerControls.vue"),
    VolumeControls: () => import("./components/VolumeControls.vue"),
    ListenerCounterModal: () => import("@/components/modal/ListenerCounterModal.vue"),
    LiveTag: () => import("@/components/audio-player/components/LiveTag"),
    SocialShareNetwork: () =>import("@/components/widget/SocialShareNetwork.vue"),
  },
  methods: {
    getUrlWithFormat,
    expand() {
      this.$store.dispatch("audioPlayer/handleExpand");
    },
    close() {
      this.$store.dispatch("audioPlayer/closePlayer");
    },
  },
  computed: {
    ...mapState({
      audioPlayer: (state) => {
        return state.audioPlayer;
      },
    }),
  },
  async mounted() {
    const cookie = this.$cookies.get("cookie");
    
    if (cookie && cookie.radioTid !== "9") {
      this.radio = cookie.radioName;
    }
  },
  beforeDestroy() {
    this.$store.dispatch("audioPlayer/unloadPlayer");
  },
};
