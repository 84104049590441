class AuthService {
  constructor(store) {
    this.$store = store
  }

  get isAuthenticated() {
    return this.$store.state.auth.isAuthenticated
  }

  get user() {
    return this.$store.state.auth.user
  }

  get sub() {
    if (!this.user) return
    return this.user.attributes.sub
  }
  get email() {
    if (!this.user) return
    return this.user.attributes.email
  }

  get firstname() {
    if (!this.user) return
    return this.user.attributes.given_name
  }

  get lastname() {
    if (!this.user) return
    return this.user.attributes.family_name
  }

  get favorite_radio() {
    if (!this.user) return
    return (this.user.attributes['custom:favorite_radio'] === undefined) ? '' : this.user.attributes['custom:favorite_radio']
  }
}

export default async ({ store }, inject) => {
  const authService = new AuthService(store)
  inject('auth', authService)
  await store.dispatch('auth/load')
}