export const state = () => ({
  id: null,
  type: null,
  name: null,
  alias: null,
  description: null,
  articles: [],
  pager: {}
});

export const mutations = {
  setId(state, id) {
    state.id = id;
  },
  setType(state, type) {
    state.type = type;
  },
  setName(state, name) {
    state.name = name;
  },
  setAlias(state, alias) {
    state.alias = alias;
  },
  setDescription(state, description) {
    state.description = description;
  },
  setPager(state, pager) {
    state.pager = pager;
  },
  setArticles(state, articles) {
    state.articles = articles;
  },
  addArticles(state, articles) {
    state.articles = [...state.articles, ...articles];
  },
};

export const actions = {
  async getFull({ commit }, params) {
    try {
      let page = await this.$defaultRepository.getByUrl(params.path);
      commit('setId', page.id);
      commit('setType', page.type);
      commit('setName', page.name);
      commit('setAlias', page.alias);
      commit('setDescription', page.description);
      commit('setArticles', page.content);
      commit('setPager', page.pager);
    } catch(e){
      throw new Error('erreur');
    }
},
  async getOtherArticles({ commit }, params) {
    try {
      let articles = await this.$nodeRepository.getByUrl(params.path, { page: params.page_id })
      commit("addArticles", articles.content);
      commit("setPager", articles.pager);
    } catch(e) {
      throw new Error(e.message);
    }
  },      
};
