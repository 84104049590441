export const state = () => ({
    modalVisible: false,
    modalComponent: null,
    modalData: null,
    modalClass: null,
    modalQueue: [],
    closeOnClickOutside: true
});

export const mutations = {
  showModal(state, data) {
    // Display only if modal is not already opened
    if (!state.modalVisible) {
      state.modalVisible = true;
      state.modalComponent = data.componentName;
      state.modalData = data.data;
      state.closeOnClickOutside = (data.closeOnClickOutside !== undefined) ? data.closeOnClickOutside : true;
    }
    else {
      // Add new modal to queue
      state.modalQueue.push(data);
    }
  },
  hideModal(state) {
    state.modalVisible = false;
    state.modalClass = null;
    state.closeOnClickOutside = true;

    // Display first item of queue
    let data = state.modalQueue.shift();
    if (typeof(data) !== 'undefined'){
      this.commit('modal/showModal', data);
    }
  },
  setClassName(state, className){
    state.modalClass = className;
  }
};
