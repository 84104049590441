export const namespaced = true;

export const state = () => ({
  mainSoundInstance: null,
  soundData: {},
  isPlaying: false,
  isExpanded: false,
  isLiveStream: false,
  isEmbed: false,
  listeningPercentage: 0,
  currentTime: 0,
  progressInterval: null,

  playListData: {
    isPlaylist: false,
    type: null,
    playListId: null,
    currentSoundId: null,
  },

  listeningCounterInterval: null,

  preRollInstance: null,
  postRollInstance: null,
  isRollPlaying: false,

  next: null,
  prev: null,
  volumeSettings: {
    mute: false,
    volume: 0.5,
    isOpen: false,
  },

  isLoading: false,
  embeddedPlayerEmission: null,

  liveUpdateTimeout: null,
  modalVisible: false,

  // Gestion des coupures réseau
  countErrors: 0,
  currentLiveSeek: 0,

  //Gestion du tracker
  trackerListernerCounter: 0,
});

export const mutations = {
  setMainSoundInstance(state, sound) {
    state.mainSoundInstance = sound;
  },
  setPreRollInstance(state, roll) {
    state.preRollInstance = roll;
  },
  setPrev(state, prev) {
    state.prev = prev;
  },
  setNext(state, next) {
    state.next = next;
  },
  setPostRollInstance(state, roll) {
    state.postRollInstance = roll;
  },
  setIsRollPlaying(state, isRollPlaying) {
    state.isRollPlaying = isRollPlaying;
  },
  resetSoundData(state) {
    state.soundData = {};
  },
  setIsPlaying(state, isPlaying) {
    state.isPlaying = isPlaying;
  },
  setSoundData(state, soundData) {
    state.soundData = { ...state.soundData, ...soundData };
  },
  setVolumeSettings(state, volumeSettings) {
    state.volumeSettings = { ...state.volumeSettings, ...volumeSettings };
  },
  setIsExpanded(state, isExpanded) {
    state.isExpanded = isExpanded;
  },
  setIsLivestream(state, isLiveStream) {
    state.isLiveStream = isLiveStream;
  },
  setIsEmbed(state, isEmbed) {
    state.isEmbed = isEmbed;
  },
  setPlayListData(state, playListData) {
    state.playListData = { ...state.playListData, ...playListData };
  },
  setListeningCounterInterval(state, interval) {
    state.listeningCounterInterval = interval;
  },
  setListeningPercentage(state, percentage) {
    state.listeningPercentage = percentage;
  },
  setIsLoading(state, value) {
    state.isLoading = value
  },
  setCurrentTime(state, value) {
    state.currentTime = value
  },
  setProgressInterval(state, interval) {
    state.progressInterval = interval;
  },
  setModalVisible(state, isVisible) {
    state.modalVisible = isVisible
  },
  setLiveUpdateTimeout(state, timeout) {
    // Clear existing timeout
    if (state.liveUpdateTimeout) {
      clearTimeout(state.liveUpdateTimeout);
    }
    // Set new timeout
    state.liveUpdateTimeout = timeout;
  },
  clearLiveUpdateTimeout(state) {
    if (state.liveUpdateTimeout) {
      clearTimeout(state.liveUpdateTimeout);
    }
    state.liveUpdateTimeout = null;
  },
  setCurrentLiveSeek(state, seek) {
    state.currentLiveSeek = seek
  },
  setCountErrors(state, countErrors) {
    state.countErrors = countErrors
  }, 
  setTrackerListernerCounter(state, trackerListernerCounter) {
    state.trackerListernerCounter = trackerListernerCounter
  }
};

export const actions = {
  /* ==========================================================
   * Gestion Data player:
   *
   * Gère les rolls, livestream, changement de piste, etc.
   * Ne concerne pas le controle du player (play, pause, etc.)
   * ========================================================== */

  async handleAudioPlayer({ state, dispatch }, soundSettings) {
    /*
     * Point d'entré du player audio,
     * Cette action est utilisé par le ButtonPlay.vue
     * Simple fonction déterminant la suite des actions à effectuer
     * selon si le player est déjà en cours de lecture ou non
     */
    if (state.mainSoundInstance || state.isRollPlaying) dispatch("changePlayingTrack", soundSettings);
    else {
      // Gestion des rolls
      let listenerCounter = this.$cookies.get("cookieListenerCounter")
      if ( !this.$auth.isAuthenticated // L'utilisateur n'est pas connecté
        && (listenerCounter && listenerCounter.ListenerNb <= this.$config.listenerMax) // Le nb d'écoute n'est pas dépassé
      ) {
        await this.$cookieService.setDonationPathway(listenerCounter.ListenerNb)
      }
      dispatch("handleRolls", soundSettings);
    }
  },
  changePlayingTrack({ state, dispatch }, newSoundSettings) {  
    if (state.soundData.id === newSoundSettings.soundData.id) return;
    dispatch("unloadPlayer");
    dispatch("handleAudioPlayer", newSoundSettings);
  },
  async handleRolls({ commit, dispatch, rootState }, soundSettings) {
    /*
     * Cette fonction est executé avant le chargement du player.
     * Elle gère la logique des rolls (preRoll, postRoll) en déterminant
     * dans un premier temps si des rolls doivent être joués.
     * Si oui, des instances audio HTML5 sont créés et stockés dans le store
     * pour chaque roll.
     *
     * Chaque instance est détruite après que le roll soit joué pour libérer
     * la mémoire.
     *
     * Les instances sont stockés dans le store pour le cas ou le player est fermé
     * par l'utilisateur avant que le roll ne puissent être joué. Dans ce cas, nous
     * pouvons détruire les instances pour encore une fois libérer la mémoire.
     */
    const preRoll =
      rootState.roll.preRolls?.[0]?.field_media_audio_file?.[0]?.url ||
      rootState.roll.preRollShow ||
      rootState.roll.preRollsGlobal?.[0]?.field_media_audio_file?.[0]?.url;

    const postRoll =
      rootState.roll.postRolls?.[0]?.field_media_audio_file?.[0]?.url ||
      rootState.roll.postRollShow ||
      rootState.roll.postRollsGlobal?.[0]?.field_media_audio_file?.[0]?.url;

    /*
     * L'instance du post roll est envoyé au lecteur principal via le
     * soundSettings. Cela nous évite de faire des conditions complex en passant
     * par le store.
     */
    const { soundData, isEmbed } = soundSettings;
    if (isEmbed) commit("setIsEmbed", true);

    if (!isEmbed && postRoll && (!soundData.partners || soundData.partners.length === 0)) {
      let {sound: postRollSound} = this.$audioPlayerService.createSoundInstance(postRoll)
      postRollSound.onended =  () => {
        dispatch("pause");
        dispatch("unloadPostRollInstance");
      }
      postRollSound.onplay = () => {
        dispatch("helperUpdateDuration", postRollSound.duration);
        commit("setIsRollPlaying", true);
      }

      commit("setPostRollInstance", postRollSound);
      soundSettings.postRoll = postRollSound;
    }
    /*
     * Le pre roll est joué immediatement si il existe puis lance
     * le chargement du player principal.
     */
    if (!isEmbed && preRoll && (!soundData.partners || soundData.partners.length === 0)) {
      function checkURL(url) {
        return new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.open('HEAD', url);
          xhr.onload = () => {
            if (xhr.status === 404) {
              reject();
            } else {
              resolve();
            }
          };
          xhr.onerror = () => {
            reject();
          };
          xhr.send();
        });
      }

      await checkURL(preRoll)
        .then(() => {
          commit("setSoundData", soundData);
    
          let {sound: preRollSound} = this.$audioPlayerService.createSoundInstance(preRoll)
          preRollSound.onended = () => {
            dispatch("resetListeningCounterInterval")
            dispatch("loadAudioPlayer", soundSettings);
            dispatch("unloadPreRollInstance");
          }
          preRollSound.onplay = () => {
            dispatch("resetProgressInterval")
            dispatch("resetListeningCounterInterval")
            dispatch("helperUpdateDuration", preRollSound.duration);
            commit("setIsRollPlaying", true);
          }
          preRollSound.oncanplay = () => {
            dispatch("resetListeningCounterInterval")
          }
          commit("setPreRollInstance", preRollSound);
          soundSettings.preRoll = preRollSound;
          dispatch("playPreRoll");
        })
        .catch(() => {
          commit("setSoundData", soundData);
          dispatch("loadAudioPlayer", soundSettings);
        });
    } else {
      commit("setSoundData", soundData);
      dispatch("loadAudioPlayer", soundSettings);
    }
  },
  async playLiveStream({ commit, dispatch, state }) {
    if (state.isPlaying) {
      dispatch("unloadPlayer");
    }
    const defaultSoundData = this.$audioPlayerService.data().defaultSoundData;
    /*
     * Le player est chargé avec les données par défaut pour faire apparaitre
     * le player en attendant le chargement de vrai data via
     * this.$audioPlayerService.getLiveSoundData()
     */
    commit("setSoundData", defaultSoundData);
    const liveSoundData = await this.$audioPlayerService.getLiveSoundData();  

    dispatch("loadAudioPlayer", {
      soundData: liveSoundData,
      autoPlay: true,
      isLiveStream: true,
    });
  },
  async playLiveEmbbedStream({ commit, dispatch, state }, radioId) {
    if (state.isPlaying) {
      dispatch("unloadPlayer");
    }
    const defaultSoundData = this.$audioPlayerService.data().defaultSoundData;
    commit("setSoundData", defaultSoundData);
    const liveSoundData = await this.$audioPlayerService.getLiveSoundData(radioId);
    dispatch("loadAudioPlayer", {
      soundData: liveSoundData,
      autoPlay: false,
      isLiveStream: true,
    });
  },
  async handleNextPrev({ commit }, playListData) {
    /*
     * Récupère les données du son précédent et suivant
     * Exécuté à chaque cycle de lecture.
     */
    const prevNext = await this.$audioPlayerService.getPrevNextSoundData(
      playListData
    );

    commit("setPlayListData", { ...playListData, isPlaylist: true });

    /*
     * Assigner null a prev et next permet de reset la valeur
     * après avoir changer de son
     * Exemple:
     * 1. On est sur le son 1: le son 1 a un next (stocké dans le store). Je click sur next.
     * 2. On passe au son 2: le son 2 n'a pas de next. la clé next du store devient null.
     */
    const prev = prevNext.prev || null;
    const next = prevNext.next || null;
    
    commit("setPrev", prev);
    commit("setNext", next);
  },
  loadAudioPlayer({ commit, state, dispatch }, soundSettings) {
    /*
     * Fonction de chargement du player principal.
     * soundData a la même structure que les données de l'API.
     *
     * Voici un exemple de structure:
     *
     * {
     *  audio_file: "",
     *  digas: "",
     *  id: "",
     *  partners: [],
     *  show: {
     *    alias: "",
     *    id: "",
     *    title: "",
     *  },
     *  title: "",
     *  url_linked_article: "",
     *  visual: {
     *    alt: "",
     *    url: "",
     *  },
     * }
     *
     * La clé roll n'est pas dans la structure car elle est géré par le store roll.
     *
     * Struture de playList:
     *
     * {
     *  type: "show" | "playlist" | "grid",
     *  playListId: "1234",
     *  currentSoundId: "1234",
     * }
     */
    const { soundData, autoPlay, isLiveStream, postRoll, playList, isEmbed, platform } =
      soundSettings;
    const listenerCounter = this.$cookies.get("cookieListenerCounter")
    // Planifie une mise à jour périodique des données du live
    if (isLiveStream) {
      dispatch('updateLiveSoundData');
      commit("setIsLivestream", isLiveStream);
    }
    if (!soundData) return

    if (!isEmbed) {
      if (listenerCounter?.ListenerNb < this.$config.listenerMax || this.$auth.isAuthenticated) {
        commit("setModalVisible", false);
      }
      else if (!isLiveStream && listenerCounter?.ListenerNb >= this.$config.listenerMax) {
        dispatch('activateMaxListeningModal')
      }
    }
    
    commit('setIsLoading', true)
    let {sound, source} = this.$audioPlayerService.createSoundInstance(soundData.audio_file, platform ?? 'site')

    if (playList) {
      dispatch("handleNextPrev", playList);
    } else {
      commit("setNext", null)
      commit("setPlayListData", null);
    }

    const gtmPodcastStartObject = this.$gtmService.getPodcastStartObject()
    this.$gtmService.push(gtmPodcastStartObject);
    
    commit("setCurrentLiveSeek", 0)

    // Get user tracked current time
    if(this.$auth.isAuthenticated && !isLiveStream) {
      commit('tracker/setUserId', this.$auth.sub, {root: true})
      commit('tracker/setMediaId', state.soundData.id, {root: true})
      dispatch("tracker/getTracker", null, {root:true})
        .then((episodeTracker) => {
          if (episodeTracker.offset > 0) {
            let offsetInSeconds = Math.trunc(episodeTracker.offset / 1000)
            state.mainSoundInstance.currentTime = offsetInSeconds
          }
        })
    }

    const relaunchPlay = () => {
      state.mainSoundInstance.load();
      state.mainSoundInstance.play();
    }
    // Onload
    sound.oncanplay = async () => {
      commit('setIsLoading', false)
    }

    const progressFunction = () => {
      if(!state.isLiveStream) {
        commit("setCurrentTime", state.mainSoundInstance.currentTime)
        this.$audioPlayerService.listeningTracking(soundData, state.mainSoundInstance.currentTime, state.mainSoundInstance)
        if(this.$auth.isAuthenticated) {
          dispatch("trackerListerner")
        }
      } else {
        commit("setCurrentTime", state.currentTime+1)
      }
  
    }

    sound.onplay = () => {
      dispatch("resetListeningCounterInterval")
      dispatch("resetProgressInterval")
      dispatch("handleListenerNb");

      // Création d'un intervalle afin d'enregistrer en continue le temps de lecture écoulé
      const progressInterval = setInterval(progressFunction, 1000)

      // On garde la référence à l'intervalle pour pouvoir le nettoyer lorsqu'on arrête l'écoute
      commit("setProgressInterval", progressInterval)
      commit("setIsPlaying", true); 
    
      if(isLiveStream) {          
        window.addEventListener('online', relaunchPlay)
      }
    }

    sound.onpause = () => {
      dispatch("resetListeningCounterInterval");

      if(isLiveStream) {          
        window.removeEventListener('online', relaunchPlay)
      }
    }

    sound.onended = () => {
      dispatch("resetListeningCounterInterval");
      dispatch("pause");
      let interval = state.progressInterval
      if (interval) {
        clearInterval(interval);
        commit("setProgressInterval", null);
      }

      if(this.$auth.isAuthenticated && !isLiveStream) {
        dispatch("resetListeningTracker")
      }
      if (!isEmbed) {
        const nextSound = state.next;
        const newPlaylistData = nextSound && {
          ...state.playListData,
          currentSoundId: nextSound.id,
        };

        const newSoundSettings = nextSound && {
          soundData: nextSound,
          playList: newPlaylistData,
        };

        if (postRoll) {
          commit("setPostRollInstance", postRoll);

          if (nextSound)
            postRoll.on("end", () => {
              newSoundSettings.autoPlay = true;
              dispatch("changePlayingTrack", newSoundSettings);
            });

          postRoll.play();
        } else if (nextSound) {
          newSoundSettings.autoPlay = true;
          dispatch("changePlayingTrack", newSoundSettings);
        }
      }
    }

    sound.onerror = (event) => {
      if(event.target.error.MEDIA_ERR_NETWORK) {
        // Gérer les erreurs de chargement du flux
        console.error('Erreur de chargement du flux audio.');
        let duration = 5000;
        switch(true) {
          case (state.countErrors < 3):
            duration = 500;
            break;
          case (state.countErrors < 6):
            duration = 1000;
            break;
          case (state.countErrors < 9):
            duration = 2000;
            break;
        }
        if (state.countErrors <= 10) {
          // Tenter une reconnexion ici
          setTimeout(function() {
            relaunchPlay()
          }, duration);
          commit("setCountErrors", state.countErrors + 1);
        }
        else {
          commit("setCountErrors", 0);
        }
      }
    }

    source.onerror = (event) => {
      console.log('Erreur lors du chargement du fichier')
      dispatch("unloadPlayer");
    }

    commit("setSoundData", soundData);
    commit("setMainSoundInstance", sound);
    commit("setIsLivestream", isLiveStream);

    if (autoPlay) {
      dispatch("play");
    }
  },

  unloadPlayer({ dispatch, state }) {
    if (state.mainSoundInstance) dispatch("unloadMainSoundInstance");
    if (state.preRollInstance) dispatch("unloadPreRollInstance");
    if (state.postRollInstance) dispatch("unloadPostRollInstance");
  },
  unloadMainSoundInstance({ dispatch, commit, state }) {
    if (state.isPlaying) {
      dispatch('pause')
    }
    state.mainSoundInstance.innerHTML = '';
    commit("setMainSoundInstance", null);
  },
  unloadPreRollInstance({ commit, state }) {
    if (state.isRollPlaying) {
      state.preRollInstance.pause();
      commit("setIsRollPlaying", false);
    }
    state.preRollInstance.innerHTML = '';
    commit("setPreRollInstance", null);
  },
  unloadPostRollInstance({ commit, state }) {
    if (state.isRollPlaying) {
      state.postRollInstance.pause();
      commit("setIsRollPlaying", false);
    }
    if (state.postRollInstance) {
      state.postRollInstance.innerHTML = '';
      commit("setPostRollInstance", null);
    }
  },
  /* ==========================================================
   * Control du player
   * ========================================================== */
  play({ state, dispatch }, params) {
    if(state.isEmbed) {
      state.mainSoundInstance.play();
    } else {
      const listenerCounter = this.$cookies.get("cookieListenerCounter")
      if (!state.isPlaying && // en pause
        (
          (this.$auth.isAuthenticated || listenerCounter.ListenerNb < this.$config.listenerMax || state.isLiveStream) || 
          (params.same === true && listenerCounter.ListenerNb === this.$config.listenerMax) // épisode déja en cours d'écoute
        )) {
        state.mainSoundInstance.play();
      } else {
        dispatch('activateMaxListeningModal')
      }
    }
  },
  pause({ commit, state }) {
    if (state.isPlaying) {
      state.mainSoundInstance.pause();
      const interval = state.progressInterval
      if (interval) {
        clearInterval(interval);
        commit("setProgressInterval", null);
      }
      commit("setIsPlaying", false);
    }
  },
  handleVolume({ commit, state }, volume) {
    if (!state.mainSoundInstance) return;
    state.mainSoundInstance.volume = volume;
    commit("setVolumeSettings", { volume });
  },
  mute({ commit, state }) {
    if (!state.mainSoundInstance) return;
    const { mute } = state.volumeSettings;
    state.mainSoundInstance.muted = !mute;
    commit("setVolumeSettings", { mute: !mute });
  },
  skip({ dispatch, state }, direction) {
    const skipToSound = state[direction];

    if (skipToSound) {
      const newPlaylistData = {
        ...state.playListData,
        currentSoundId: skipToSound.id,
      };

      dispatch("changePlayingTrack", {
        soundData: skipToSound,
        playList: newPlaylistData,
        autoPlay: true,
      });
    }
  },
  switchOpenVolumeSettings({ commit }, isOpen) {
    commit("setVolumeSettings", { isOpen });
  },
  handleExpand({ commit, state }) {
    const { isExpanded } = state;
    commit("setIsExpanded", !isExpanded);
  },
  closePlayer({ commit, dispatch, state }) {
    const interval = state.progressInterval
    const listeningCounterInterval = state.listeningCounterInterval
    if (interval) {
      clearInterval(interval);
      commit("setProgressInterval", null);
    }
    if(listeningCounterInterval) {
      dispatch('resetListeningCounterInterval')
     }
    if (state.isLiveStream) {
      clearTimeout(state.liveUpdateTimeout);
      state.liveUpdateTimeout = null;
    }
    commit("resetSoundData");
  },
  setListeningPercentage({ commit }, percentage) {
    commit("setListeningPercentage", percentage);
  },
  /* ==========================================================
   * Gère le nombre d'écoute
   * ========================================================== */
  resetListeningCounterInterval({ commit, state }) {
    clearInterval(state.listeningCounterInterval);
    commit("setListeningCounterInterval", null);
  },
  resetProgressInterval({ commit, state }) {
    clearInterval(state.progressInterval);
    commit("setProgressInterval", null);
  },
  handleListenerNb({ commit, state }) {
    const listenerCounter = this.$cookies.get("cookieListenerCounter")

    let hasIncremented = false
    
    if (!this.$auth.isAuthenticated && !state.isLiveStream) {
      const interval = setInterval(() => {
        const currentTime = state.mainSoundInstance.currentTime;
  
        if (currentTime > 30 && !hasIncremented) {
          if (listenerCounter.ListenerNb < this.$config.listenerMax) {
            listenerCounter.ListenerNb += 1
            hasIncremented = true;

            this.$cookies.set("cookieListenerCounter", listenerCounter, {
              path: '/',
              maxAge: 60 * 60 * 24 * 30
            });
          }
        }
      }, 1000);
      commit("setListeningCounterInterval", interval);
    }
  },
  /* ==========================================================
   * Fonctions utilitaires
   * ========================================================== */
  helperUpdateDuration({ commit }, duration) {
    commit("setSoundData", { duration: duration * 1000 });
  },
  stopLiveUpdateTimeout({ commit, state }) {
    if (state.liveUpdateTimeout) {
      clearTimeout(state.liveUpdateTimeout);
      const interval = state.progressInterval
      clearInterval(interval);
      commit("setLiveUpdateTimeout", null);
      commit('setProgressInterval', null);
    }
  },

  updateLastTime({ commit }, currentTime) {
    commit('setLastTime', currentTime)
  },

  async updateLiveSoundData({ commit, dispatch, state }) {
    dispatch("resetListeningCounterInterval")
    // Clear existing timeout before setting a new one
    commit('clearLiveUpdateTimeout');
    // Your logic to fetch live sound data
    const liveSoundData = await this.$audioPlayerService.getLiveSoundData();
    commit("setSoundData", liveSoundData);
    commit('setCurrentTime', 0);
    let nextUpdate
    // Schedule next update
    if(state.soundData.timeLeft < 0) {
      nextUpdate = 60000
    } else if (state.soundData.timeLeft < 300000) {
      nextUpdate = state.soundData.timeLeft
    } else {
      nextUpdate = 300000
    }
    
    commit("setLiveUpdateTimeout", setTimeout(() => {
      dispatch('updateLiveSoundData');
    }, nextUpdate));
  },

  activateMaxListeningModal({ commit }) {
    commit("setCurrentTime", 0)
    commit("setModalVisible", true)
    let modal = document.querySelector('.listener-counter.modal')
    if (modal && modal.classList.contains('is-inactive'))
      modal.classList.replace('is-inactive', 'is-active')
  },

  resetListeningTracker({dispatch}){
    dispatch('tracker/resetListeningTrackerData', null, {root: true})
  },

  async trackerListerner({commit, dispatch, state}) {
    state.trackerListernerCounter < 10 ? 
      commit('setTrackerListernerCounter', state.trackerListernerCounter + 1) : 
      commit('setTrackerListernerCounter', 0)
    
    if (state.trackerListernerCounter === 10) { // s'exécute toutes les 10 secondes
      
      commit("tracker/setMediaId", state.soundData.id, { root: true })
      commit("tracker/setUserId", this.$auth.sub, { root: true })
      commit("tracker/setStatus", 1, { root: true })
      dispatch("tracker/updateListeningTracker", null, { root: true })
    }
  },

  playPreRoll({ state, commit }) {
    if (state.preRollInstance) {
      state.preRollInstance.play();
  
      commit("setIsRollPlaying", true);
    }
  },
};
