import createRepository from "~/services/apiService";
import audioPlayerService from "~/services/audioPlayerService";
import converterService from "~/services/converterService";
import obfuscateService from "~/services/obfuscateService";
import cookieService from "~/services/cookieService";
import ckEditorService from "~/services/ckEditorService";
import headService from "~/services/headService";
import searchService from "~/services/searchService";
import gtmService from "~/services/gtmService";
import cognitoTranslation from "~/services/cognitoTranslation";

export default (ctx, inject) => {
  // API Part
  const repositoryWithAxios = createRepository(ctx.$axios);
  inject("defaultRepository", repositoryWithAxios(""));
  inject("articlesRepository", repositoryWithAxios("/articles"));
  inject("articlesTopRepository", repositoryWithAxios("/articles-top"));
  inject("nodeRepository", repositoryWithAxios("/node"));
  inject("menuRepository", repositoryWithAxios("/entity/menu/main/tree"));
  inject("mediaRepository", repositoryWithAxios("/media"));
  inject("taxonomyRepository", repositoryWithAxios("/terms"));
  inject("taxonomyTermRepository", repositoryWithAxios("/taxonomy/term"));
  inject("radiosRepository", repositoryWithAxios("/terms/radio"));
  inject("podcastsRepository", repositoryWithAxios("/podcasts"));
  inject(
    "podcastsLandingPageRepository",
    repositoryWithAxios("/rest_region/podcast")
  );
  inject("episodeRepository", repositoryWithAxios("/api/episode"));
  inject(
    "podcastSelectionRepository",
    repositoryWithAxios("/podcastSelection")
  );
  inject("regionRepository", repositoryWithAxios("/rest_region"));
  inject("webformRepository", repositoryWithAxios("/webform_rest"));
  inject(
    "webformSubmitRepository",
    repositoryWithAxios("/webform_rest/submit")
  );
  inject("webformObjectRepository", repositoryWithAxios("/webform"));
  inject("foldersRepository", repositoryWithAxios("/dossiers"));
  inject("folderRepository", repositoryWithAxios("/dossier"));
  inject("rolls", repositoryWithAxios("/rolls"));
  inject("flags", repositoryWithAxios("/entity/flagging"));
  inject("trackingReplay", repositoryWithAxios("rcf_tracking_replay"));
  inject(
    "postTrackingReplay",
    repositoryWithAxios("entity/rcf_tracking_replay")
  );
  inject("tagByNameRepository", repositoryWithAxios("/tag_contents"));
  inject("headerRepository", repositoryWithAxios("/rest_header_content"));
  inject("aroundMeRepository", repositoryWithAxios("/pres-de-chez-moi"));
  inject("saintRepository", repositoryWithAxios("/rest_saintjour"));
  inject("eventsRepository", repositoryWithAxios("/events"));
  inject("episodePerPartnerRepository", repositoryWithAxios("/episodes_by_partner"));
  inject("articlesPerPartnerRepository", repositoryWithAxios("/articles_by_partner"));
  inject('articlePreviewRepository', repositoryWithAxios('/preview'));
  inject('episodesByShowRepository', repositoryWithAxios('/episodes_by_show'));

  // Player Part
  const audioPlayer = audioPlayerService(
    ctx.app.store,
    ctx.$axios,
    ctx.app.$cookies,
    ctx.$config,
    ctx.app,
    ctx
  );
  inject("audioPlayerService", audioPlayer());

  // Conversion Part
  const converter = converterService();
  inject("converterService", converter());

  // Obfuscate Part
  const obfuscate = obfuscateService(ctx);
  inject("obfuscateService", obfuscate());

  // Cookie Part
  const cookie = cookieService(
    ctx.app.$cookies,
    ctx.app.store,
    ctx.app.$config,
    ctx.$axios
  );
  inject("cookieService", cookie());

  // CkEditor Part
  const ckeditor = ckEditorService();
  inject("ckEditorService", ckeditor());

  // head Part
  const head = headService(ctx.app.$config, ctx.route);
  inject("headService", head());

  // head Part
  const search = searchService(
    ctx.app.store,
    ctx.$axios,
    ctx.app.$config,
    ctx.route
  );
  inject("searchService", search());

  // gtm Part
  const gtm = gtmService(
    ctx.app.store,
    ctx.app.$config,
    ctx.app.$cookies,
    ctx.app
  );
  inject("gtmService", gtm());

  // Cognito translation Part
  const translation = cognitoTranslation();
  inject("cognitoTranslation", translation());
};
