export const state = () => ({
  oneSlug: {
    type: null,
    radio: null,
  },
  twoSlugs: {
    type: null,
  },
});

export const mutations = {
  setOneSlugType(state, type) {
    state.oneSlug.type = type;
  },
  setOneSlugRadio(state, radio) {
    state.oneSlug.radio = radio;
  },
  setTwoSlugsType(state, type) {
    state.twoSlugs.type = type;
  },
};

export const actions = {
  setOneSlugType({ commit }, params) {
    commit("setOneSlugType", params.type);
  },
  setOneSlugRadio({ commit }, params) {
    commit("setOneSlugRadio", params.radio);
  },
  setTwoSlugsType({ commit }, params) {
    commit("setTwoSlugsType", params.type);
  },

  resetOneSlugRadio({ commit }) {
    commit("setOneSlugRadio", null);
  },
  resetOneSlugType({ commit }) {
    commit("setOneSlugType", null);
  },
  resetTwoSlugsType({ commit }) {
    commit("setTwoSlugsType", null);
  },
};
