const https = require('https');

export default function ({ $axios, $config }, inject) {
  // Create a custom axios instance
  const proxyContact = $axios.create({
    baseURL: $config.proxyContactUrl,
    httpsAgent: new https.Agent({  
      rejectUnauthorized: false
    })
  })

  // Inject to context as $proxyContact
  inject('proxyContact', proxyContact)
}