export default async function ({ store, route, app }) {
  if (route.query.radio) {
    await store.dispatch('radio/getRadioByCode', {radioCode: route.query.radio})
  }
  else if(app.$cookies.get('cookie')) { // update radio data
    await store.dispatch('radio/getRadioByCode', app.$cookies.get('cookie'))
  } 
  else if (!app.$cookies.get('cookie')) { // get national radio data
    await store.dispatch('radio/getRadioNational', { radioCode: app.$config.nationalCode })
  }
}