
  export default {
    name: "PageTitle",
    props: [
      "text",
      "link_label",
      "link_url",
      "text_start",
      "no_margin",
      "radio",
      "isHomePage",
    ],
    methods: {
      setClass(){
        if (this.text_start) {
          return 'text-start'
        }
        if (this.link_label) {
          return 'text-center'
        }
        if (this.isHomePage) {
          return 'desktop:text-5xl'
        }
      }
    },
    computed: {
       getPage() {
        return this.$route.query.page ? " - page " + this.$route.query.page : ""
      }
    },
  }
